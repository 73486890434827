import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import NameChip from "./NameChip";

function Header({ setIsNavSlide }) {
  const isLogin = useSelector((state) => state.auth.isLogin);
  const user = useSelector((state) => state.auth.user);
  const userData = useSelector((state) => state.auth.userData);

  const navigate = useNavigate();

  return (
    <>
      <nav className="grid h-[80px] grid-cols-3 items-center px-[20px] text-xl font-medium md:h-[100px] md:px-[40px] lg:h-[140px] lg:px-[100px]">
        <NavLink to="/" className="col-span-1 justify-self-start">
          LOGO
        </NavLink>
        <div className="col-span-1 hidden w-full justify-evenly lg:flex  ">
          <div className="nav-link relative hover:text-primary-60">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? "text-primary-60" : "")}
              id="about"
            >
              About
            </NavLink>
          </div>
          <div className="nav-link relative hover:text-primary-60">
            <NavLink
              to="registry"
              className={({ isActive }) => (isActive ? "text-primary-60" : "")}
              id="registry"
            >
              Registry
            </NavLink>
          </div>
        </div>
        {isLogin && user && userData ? (
          <div className="col-span-1 hidden w-full justify-evenly lg:flex">
            <div className="flex h-[43px] w-[148px] items-center"></div>
            <NameChip
              userName={userData.firstName ?? "default username"}
              onClick={() => {
                navigate("/registry/" + user.username);
              }}
              size="large"
            />
          </div>
        ) : (
          <div className=" col-span-1 hidden w-full items-center justify-evenly lg:flex">
            <NavLink
              to="/auth/login"
              className={({ isActive }) =>
                "relative  flex w-[148px] items-center justify-center " +
                (isActive ? "text-primary-80" : "text-primary-40")
              }
              id="login"
            >
              Log In
            </NavLink>
            <NavLink
              to="join"
              className={({ isActive }) =>
                "flex h-[43px]  w-[148px] items-center justify-center rounded-md text-white " +
                (isActive ? "bg-primary-80" : "bg-primary-40")
              }
              id="join"
            >
              Join
            </NavLink>
          </div>
        )}
        <div className="col-span-2 block lg:hidden">
          <div className="flex justify-end">
            <svg
              className="cursor-pointer mobile:h-[36px] mobile:w-[36px]"
              onClick={() => {
                setIsNavSlide(true);
              }}
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              viewBox="0 96 960 960"
              width="48"
            >
              <path d="M151 841q-19.35 0-32.675-12.86Q105 815.281 105 795.64 105 776 118.325 763T151 750h659q18.375 0 31.688 13.375Q855 776.751 855 795.491q0 20.141-13.312 32.825Q828.375 841 810 841H151Zm0-219q-19.35 0-32.675-13.56Q105 594.881 105 576.14q0-20.14 13.325-32.64T151 531h659q18.375 0 31.688 12.675Q855 556.351 855 575.991q0 19.641-13.312 32.825Q828.375 622 810 622H151Zm0-220q-19.35 0-32.675-13.56Q105 374.881 105 356.14 105 336 118.325 323T151 310h659q18.375 0 31.688 13.175Q855 336.351 855 355.991q0 19.641-13.312 32.825Q828.375 402 810 402H151Z" />
            </svg>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
