import { Link } from "react-router-dom";

import globalAffairCanada from "../assets/images/Logo-globalAffairCanada.avif";
import collegeInstitutesCanada from "../assets/images/Logo-collegeInstitutesCanada.png";
import sage from "../assets/images/Logo-sage.png";
import algonquinCollege from "../assets/images/Logo-algonquinCollege.png";
import dominicaStateCollege from "../assets/images/Logo-dominicaStateCollege.avif";

function Footer({ className = "" }) {
  return (
    <div className={className}>
      <div className="w-full bg-gray-100 px-6 pb-[24px] pt-[123px] font-sans text-xl md:px-12 tablet:pt-[62px] tablet:text-base  mobile:pt-[47px]">
        <footer className="mx-auto flex max-w-6xl gap-6 md:gap-12 mobile:flex-col-reverse">
          <div className="flex flex-col gap-[40px]">
            <div className="flex flex-col gap-[26px]">
              <span className="text-[#434D56]">Funded by</span>
              <div className="grid grid-cols-3 tablet:grid-cols-2 ">
                <a href="https://www.international.gc.ca">
                  <img src={globalAffairCanada} alt="global affairs canada" />
                </a>
                <a href="https://www.collegesinstitutes.ca">
                  <img
                    src={collegeInstitutesCanada}
                    alt="college & institutes canada"
                  />
                </a>
                <a href="/">
                  <img
                    src={sage}
                    alt="skills to access the green economy SAGE"
                  />
                </a>
              </div>
            </div>
            <div className="flex flex-col  gap-[.5rem] gap-[26px]">
              <span className="text-[#434D56]">Developed by</span>
              <div className="grid grid-cols-2 items-center gap-8">
                <a href="https://www.algonquincollege.com">
                  <img src={algonquinCollege} alt="Algonquin College Logo" />
                </a>
                <a href="http://dsc.edu.dm">
                  {/* This logo should be replaced with a transparent background */}
                  <img
                    src={dominicaStateCollege}
                    alt="Dominica State College Logo"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-start-3 mx-auto flex flex-col gap-[16px] mobile:mx-0 mobile:flex-row mobile:justify-between">
            <div className="flex flex-col gap-[16px] font-medium text-[#434D56]">
              <Link
                to="/"
                className="text-left"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                About
              </Link>
              <Link
                to="registry"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                Registry
              </Link>
              <Link
                to="join"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                Join
              </Link>
            </div>
            <div className="flex flex-col gap-[8px]">
              {/* I moved the Contact span to here because it is part of email and telNumber
              and it should be in a separate column on mobile pages.  */}
              <span className=" font-medium text-gray-600">Contact</span>
              <ul className="flex list-none flex-col gap-[8px] text-green-700">
                <li>
                  <a
                    className="flex flex-nowrap gap-2 text-[14px] md:gap-6 md:text-[16px] lg:text-[19px] "
                    href="mailto:DSC@dsc.edu.dm"
                  >
                    <svg width={24} height={24} fill="none">
                      <rect
                        x={2}
                        y={4}
                        width={20}
                        height={16}
                        rx={5}
                        stroke="#166534"
                        strokeWidth={1.5}
                      />
                      <path
                        d="M6 9l4.8 3.6a2 2 0 002.4 0L18 9"
                        stroke="#166534"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    DSC@dsc.edu.dm
                  </a>
                </li>
                <li className="flex flex-nowrap gap-2 text-[14px] md:gap-6 md:text-[16px] lg:text-[19px]">
                  <svg
                    width={24}
                    height={24}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.722 2.421c.233.04.54.159.758.458l1.943 2.654c.4.545.484 1.259.223 1.882l-.865 2.068c-.019.044-.007.076.002.09a19.098 19.098 0 002.136 2.513c.878.879 1.72 1.575 2.51 2.13.013.01.045.02.09.002l2.067-.865a1.927 1.927 0 011.882.223l2.654 1.943c.3.219.418.525.458.759.038.223.016.435-.013.597-.058.326-.19.67-.322.962-.267.595-.63 1.195-.806 1.456l-.128.19-.008.012c-.356.529-.89 1.322-1.768 1.715-1.518.68-3.476.415-5.395-.372-1.927-.791-3.978-2.172-5.839-4.015a.88.88 0 01-.045-.042c-1.883-1.882-3.292-3.964-4.094-5.92-.788-1.918-1.052-3.877-.373-5.395.393-.878 1.186-1.412 1.715-1.769l.012-.008.19-.127c.261-.177.861-.54 1.457-.807a4.33 4.33 0 01.961-.322c.162-.029.374-.05.598-.012zm.733 13.013a.864.864 0 01.038.037c1.741 1.74 3.629 3.003 5.33 3.701 1.737.713 3.122.778 3.976.395.346-.155.623-.501 1.02-1.09l.128-.19c.132-.196.44-.706.656-1.187.063-.14.11-.26.142-.358l-2.34-1.714a.127.127 0 00-.124-.014l-2.068.865a1.892 1.892 0 01-1.82-.191 20.892 20.892 0 01-2.753-2.333c-.95-.95-1.712-1.87-2.329-2.746a1.893 1.893 0 01-.19-1.82l.864-2.069a.127.127 0 00-.014-.124l-1.714-2.34c-.098.032-.218.079-.358.142a9.239 9.239 0 00-1.187.656l-.19.128c-.588.397-.935.674-1.09 1.02-.382.854-.318 2.239.395 3.976.688 1.678 1.925 3.536 3.628 5.256z"
                      fill="#166534"
                    />
                  </svg>
                  <p>123-456-789</p>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
      <div className="w-full px-12">
        <div className="mx-auto my-auto flex h-[76px] max-w-6xl flex-row items-center justify-between text-[17px] text-gray-400  tablet:h-[143px] tablet:flex-col tablet:justify-center mobile:h-[66px] mobile:text-[10px]">
          <p>Copyright 2023. Dominica State College. All rights reserved</p>
          <div className="flex gap-3">
            {/* TODO: Add a valid link */}
            <a href="/">Privacy Policy</a>
            <span>|</span>
            <a href="/">Terms of Use</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
