/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createServices = /* GraphQL */ `
  mutation CreateServices(
    $input: CreateServicesInput!
    $condition: ModelServicesConditionInput
  ) {
    createServices(input: $input, condition: $condition) {
      id
      name
      color
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateServices = /* GraphQL */ `
  mutation UpdateServices(
    $input: UpdateServicesInput!
    $condition: ModelServicesConditionInput
  ) {
    updateServices(input: $input, condition: $condition) {
      id
      name
      color
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteServices = /* GraphQL */ `
  mutation DeleteServices(
    $input: DeleteServicesInput!
    $condition: ModelServicesConditionInput
  ) {
    deleteServices(input: $input, condition: $condition) {
      id
      name
      color
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createProjects = /* GraphQL */ `
  mutation CreateProjects(
    $input: CreateProjectsInput!
    $condition: ModelProjectsConditionInput
  ) {
    createProjects(input: $input, condition: $condition) {
      id
      name
      images
      description
      ownerId
      ProjectBelongToCarpenter {
        id
        phone
        firstName
        lastName
        businessName
        address
        email
        project
        services
        rebuild
        beAMentor
        beMentored
        isApproved
        parish
        userStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProjects = /* GraphQL */ `
  mutation UpdateProjects(
    $input: UpdateProjectsInput!
    $condition: ModelProjectsConditionInput
  ) {
    updateProjects(input: $input, condition: $condition) {
      id
      name
      images
      description
      ownerId
      ProjectBelongToCarpenter {
        id
        phone
        firstName
        lastName
        businessName
        address
        email
        project
        services
        rebuild
        beAMentor
        beMentored
        isApproved
        parish
        userStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteProjects = /* GraphQL */ `
  mutation DeleteProjects(
    $input: DeleteProjectsInput!
    $condition: ModelProjectsConditionInput
  ) {
    deleteProjects(input: $input, condition: $condition) {
      id
      name
      images
      description
      ownerId
      ProjectBelongToCarpenter {
        id
        phone
        firstName
        lastName
        businessName
        address
        email
        project
        services
        rebuild
        beAMentor
        beMentored
        isApproved
        parish
        userStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCarpenters = /* GraphQL */ `
  mutation CreateCarpenters(
    $input: CreateCarpentersInput!
    $condition: ModelCarpentersConditionInput
  ) {
    createCarpenters(input: $input, condition: $condition) {
      id
      phone
      firstName
      lastName
      businessName
      address
      email
      project
      services
      rebuild
      beAMentor
      beMentored
      isApproved
      parish
      CarpenterHasManyProjects {
        nextToken
        startedAt
      }
      userStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCarpenters = /* GraphQL */ `
  mutation UpdateCarpenters(
    $input: UpdateCarpentersInput!
    $condition: ModelCarpentersConditionInput
  ) {
    updateCarpenters(input: $input, condition: $condition) {
      id
      phone
      firstName
      lastName
      businessName
      address
      email
      project
      services
      rebuild
      beAMentor
      beMentored
      isApproved
      parish
      firstTimeLogin
      CarpenterHasManyProjects {
        nextToken
        startedAt
      }
      userStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCarpenters = /* GraphQL */ `
  mutation DeleteCarpenters(
    $input: DeleteCarpentersInput!
    $condition: ModelCarpentersConditionInput
  ) {
    deleteCarpenters(input: $input, condition: $condition) {
      id
      phone
      firstName
      lastName
      businessName
      address
      email
      project
      services
      rebuild
      beAMentor
      beMentored
      isApproved
      parish
      CarpenterHasManyProjects {
        nextToken
        startedAt
      }
      userStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
