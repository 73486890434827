import { forwardRef } from "react";
const Card = forwardRef(({ children, className, ...otherProps }, ref) => {
  return (
    <div
      className={`flex justify-center border-solid border-[0.5px] items-center bg-white rounded-[10px] ${
        className ? className : ""
      }`}
      ref={ref}
      {...otherProps}
    >
      {children}
    </div>
  );
});

export default Card;
