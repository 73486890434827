import React from "react";
import ReactDOM from "react-dom";
import Card from "./Card";
import useDisableScroll from "../hooks/useDisableScroll";
import { CSSTransition } from "react-transition-group";
import { useRef } from "react";

export const Backdrop = ({ className, scrollTop, showWhen, children }) => {
  // useDisableScroll(scrollTop);
  const nodeRef = useRef();

  return (
    <CSSTransition
      in={showWhen}
      timeout={700}
      mountOnEnter
      unmountOnExit
      classNames="backdrop"
      nodeRef={nodeRef}
    >
      <div
        className={`${className} fixed top-0 left-0 right-0 z-[888] flex h-screen w-screen items-center justify-center bg-modal-backdrop bg-opacity-0`}
        ref={nodeRef}
      >
        {children}
      </div>
    </CSSTransition>
  );
};

const Modal = ({
  className,
  backdropClassName,
  children,
  onClickOnBackDrop,
  scrollTop = false,
  toggle,
}) => {
  return ReactDOM.createPortal(
    <CSSTransition
      in={toggle}
      timeout={300}
      unmountOnExit
      mountOnEnter
      classNames="modal"
    >
      <Backdrop scrollTop={scrollTop} showWhen={toggle}>
        <div
          className="fixed left-0 h-screen w-screen"
          onClick={onClickOnBackDrop}
        />
        <CSSTransition
          in={toggle}
          timeout={500}
          unmountOnExit
          mountOnEnter
          classNames="modal"
        >
          <Card className={` z-[8888] shadow-modal-shadow ${className}`}>
            {children}
          </Card>
        </CSSTransition>
      </Backdrop>
    </CSSTransition>,
    document.getElementById("overlay-root")
  );
};

export default Modal;
