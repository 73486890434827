import { NavLink, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { Backdrop } from "./Modal";
import NameChip from "./NameChip";
import { useDispatch } from "react-redux";
import { signOut } from "../store/auth";

function SlideNavBar({ setIsNavSlide, isNavSlide }) {
  const isLogin = useSelector((state) => state.auth.isLogin);
  const userData = useSelector((state) => state.auth.userData);

  const navigate = useNavigate();
  const nodeRef = useRef();
  const dispatch = useDispatch();

  return (
    <div className="block lg:hidden">
      <CSSTransition
        in={isNavSlide}
        mountOnEnter
        unmountOnExit
        timeout={500}
        classNames="slideNavBar"
        nodeRef={nodeRef}
      >
        <Backdrop scrollTop={true} className="z-[9999]" showWhen={isNavSlide}>
          <div
            className="fixed top-0 right-0 h-screen  w-screen bg-white md:w-[360px]"
            ref={nodeRef}
          >
            <div className="mt-[14px] grid h-fit grid-cols-2 items-center justify-between md:mt-[52px]">
              {/* ERROR: currentCarpenter is undefined pages other than profile because state of registry gets reset. */}
              {isLogin && userData ? (
                <div className="ml-[16px] mr-auto">
                  <NameChip
                    userName={userData.firstName}
                    onClick={() => {
                      navigate(`/registry/${userData.id}`);
                    }}
                    size="medium"
                  />
                </div>
              ) : (
                <div className="h-[1px] w-[1px]"></div>
              )}
              <svg
                className="ml-auto mr-[20px] cursor-pointer"
                onClick={() => {
                  // When the close button clicked
                  setIsNavSlide(false);
                }}
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 96 960 960"
                width="24"
              >
                <path d="M256 842.153 213.847 800l224-224-224-224L256 309.847l224 224 224-224L746.153 352l-224 224 224 224L704 842.153l-224-224-224 224Z" />
              </svg>
            </div>

            <nav className="mt-[92px] flex h-[calc(100%-48px-14px-92px)] flex-col gap-[12px] md:mt-[86px] md:h-auto">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  "flex h-[43px] items-center hover:border-l-[5px] hover:border-primary-60 hover:bg-grey-20" +
                  " " +
                  (isActive
                    ? "border-l-[5px] border-primary-60 bg-grey-20"
                    : "border-l-[5px] border-white hover:border-[#F8F8F8]")
                }
                onClick={() => {
                  setIsNavSlide(false);
                }}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-[15px] mr-[34px]"
                >
                  <path
                    d="M1.5 9.93841C1.5 8.71422 2.06058 7.55744 3.02142 6.79888L8.52142 2.45677C9.97466 1.30948 12.0253 1.30948 13.4786 2.45677L18.9786 6.79888C19.9394 7.55744 20.5 8.71422 20.5 9.93841V16.5C20.5 18.7091 18.7091 20.5 16.5 20.5H15C14.4477 20.5 14 20.0523 14 19.5V16.5C14 15.3954 13.1046 14.5 12 14.5H10C8.89543 14.5 8 15.3954 8 16.5V19.5C8 20.0523 7.55228 20.5 7 20.5H5.5C3.29086 20.5 1.5 18.7091 1.5 16.5L1.5 9.93841Z"
                    stroke="#434D56"
                    strokeWidth="1.5"
                  />
                </svg>
                <span className="text-xl font-normal text-dark-secondary md:font-medium">
                  About
                </span>
              </NavLink>
              <NavLink
                to="/registry/"
                className={({ isActive }) =>
                  "flex h-[43px] items-center hover:border-l-[5px] hover:border-primary-60 hover:bg-grey-20" +
                  " " +
                  (isActive
                    ? "border-l-[5px] border-primary-60 bg-grey-20"
                    : "border-l-[5px] border-white hover:border-[#F8F8F8]")
                }
                onClick={() => {
                  setIsNavSlide(false);
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-[15px] mr-[34px]"
                >
                  <path
                    d="M19 12V8.7241C19 8.25623 18.836 7.80316 18.5364 7.44373L14.5997 2.71963C14.2197 2.26365 13.6568 2 13.0633 2H11H7C4.79086 2 3 3.79086 3 6V18C3 20.2091 4.79086 22 7 22H12"
                    stroke="#434D56"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M16 19H22"
                    stroke="#434D56"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19 16L19 22"
                    stroke="#434D56"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 2.5V6C14 7.10457 14.8954 8 16 8H18.5"
                    stroke="#434D56"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
                <span className=" text-xl font-normal text-dark-secondary md:font-medium">
                  Registry
                </span>
              </NavLink>
              {isLogin && (
                <div className="mb-[41px] flex flex-1 items-end justify-center md:block">
                  <button
                    onClick={() => {
                      dispatch(signOut());
                      setIsNavSlide(false);
                    }}
                    className={
                      "mt-auto flex h-[43px] items-center justify-center border-l-[5px] border-white hover:border-l-[5px] md:w-full md:justify-start  md:hover:border-[#F8F8F8] md:hover:border-l-primary-60 md:hover:bg-grey-20"
                    }
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-[16px] md:ml-[15px] md:mr-[34px]"
                    >
                      <path
                        d="M16 16V18C16 20.2091 14.2091 22 12 22H7C4.79086 22 3 20.2091 3 18V6C3 3.79086 4.79086 2 7 2H12C14.2091 2 16 3.79086 16 6V8"
                        stroke="#434D56"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M19 15L21.2929 12.7071C21.6834 12.3166 21.6834 11.6834 21.2929 11.2929L19 9"
                        stroke="#434D56"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M21 12L9 12"
                        stroke="#434D56"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>

                    <span className="text-xl font-normal text-dark-secondary md:font-medium">
                      Logout
                    </span>
                  </button>
                </div>
              )}

              {!isLogin && (
                <>
                  <NavLink
                    to="/auth/login"
                    className={({ isActive }) =>
                      "flex h-[43px] items-center hover:border-l-[5px] hover:border-primary-60 hover:bg-grey-20" +
                      " " +
                      (isActive
                        ? "border-l-[5px] border-primary-60 bg-grey-20"
                        : "border-l-[5px] border-white hover:border-[#F8F8F8]")
                    }
                    onClick={() => {
                      setIsNavSlide(false);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-[15px] mr-[34px]"
                    >
                      <circle
                        cx="4"
                        cy="4"
                        r="4"
                        transform="matrix(-1 0 0 1 16 3)"
                        stroke="#434D56"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M5 16.9347C5 16.0743 5.54085 15.3068 6.35109 15.0175V15.0175C10.004 13.7128 13.996 13.7128 17.6489 15.0175V15.0175C18.4591 15.3068 19 16.0743 19 16.9347V18.2502C19 19.4376 17.9483 20.3498 16.7728 20.1818L15.8184 20.0455C13.2856 19.6837 10.7144 19.6837 8.18162 20.0455L7.22721 20.1818C6.0517 20.3498 5 19.4376 5 18.2502V16.9347Z"
                        stroke="#434D56"
                        strokeWidth="1.5"
                      />
                    </svg>
                    <span className="text-xl font-normal text-dark-secondary md:font-medium">
                      Log In
                    </span>
                  </NavLink>
                  <NavLink
                    to="/join"
                    className={({ isActive }) =>
                      "flex h-[43px] items-center hover:border-l-[5px] hover:border-primary-60 hover:bg-grey-20" +
                      " " +
                      (isActive
                        ? "border-l-[5px] border-primary-60 bg-grey-20"
                        : "border-l-[5px] border-white hover:border-[#F8F8F8]")
                    }
                    onClick={() => {
                      setIsNavSlide(false);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-[15px] mr-[34px]"
                    >
                      <path
                        d="M17.7189 9.52728L19.8442 10.677L20.2891 10.9269C21.237 11.4595 21.237 12.8325 20.2891 13.365L14.2525 16.7566C12.8524 17.5432 11.1476 17.5432 9.74754 16.7566L3.71092 13.365C2.76302 12.8325 2.76303 11.4595 3.71093 10.9269L4.15585 10.677L6.16334 9.52728M18.1811 14.6461L20.1129 15.6185C21.1092 16.1201 21.1412 17.5398 20.1685 18.0862L14.2525 21.4101C12.8524 22.1966 11.1476 22.1966 9.74754 21.4101L3.9178 18.1347C2.9284 17.5789 2.98291 16.1278 4.0112 15.6487L6.16334 14.6461M14.2525 11.8112L20.2891 8.41961C21.237 7.88705 21.237 6.51405 20.2891 5.98149L14.2525 2.58993C12.8524 1.80336 11.1476 1.80336 9.74754 2.58993L3.71093 5.98149C2.76303 6.51405 2.76302 7.88705 3.71092 8.41961L9.74754 11.8112C11.1476 12.5977 12.8524 12.5977 14.2525 11.8112Z"
                        stroke="#434D56"
                        strokeWidth="1.5"
                      />
                    </svg>
                    <span className="text-xl font-normal text-dark-secondary md:font-medium">
                      Join
                    </span>
                  </NavLink>
                </>
              )}
            </nav>
          </div>
        </Backdrop>
      </CSSTransition>
    </div>
  );
}

export default SlideNavBar;
