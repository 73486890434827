import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsMobile, setIsTablet, setIsDesktop } from "../store/mediaQuery";
const useWindowSize = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    let lastWidth = 0;

    const handleResize = () => {
      const currentWidth = window.innerWidth;
      //Only change the state for every 256px changed
      const generalCondition =
        currentWidth !== lastWidth && Math.abs(currentWidth - lastWidth) >= 256;

      if (generalCondition && currentWidth < 768) {
        dispatch(setIsMobile(true));
        lastWidth = currentWidth;
      }
      if (generalCondition && currentWidth >= 768 && currentWidth < 1024) {
        dispatch(setIsTablet(true));
        lastWidth = currentWidth;
      }
      if (generalCondition && currentWidth >= 1024) {
        dispatch(setIsDesktop(true));
        lastWidth = currentWidth;
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);
};

export default useWindowSize;
