function IconPlus() {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.2031 9C17.2031 9.26107 17.0994 9.51145 16.9148 9.69606C16.7302 9.88066 16.4798 9.98438 16.2188 9.98438H9.98438V16.2188C9.98438 16.4798 9.88066 16.7302 9.69606 16.9148C9.51145 17.0994 9.26107 17.2031 9 17.2031C8.73893 17.2031 8.48855 17.0994 8.30394 16.9148C8.11934 16.7302 8.01562 16.4798 8.01562 16.2188V9.98438H1.78125C1.52018 9.98438 1.2698 9.88066 1.08519 9.69606C0.900586 9.51145 0.796875 9.26107 0.796875 9C0.796875 8.73893 0.900586 8.48855 1.08519 8.30394C1.2698 8.11934 1.52018 8.01562 1.78125 8.01562H8.01562V1.78125C8.01562 1.52018 8.11934 1.2698 8.30394 1.08519C8.48855 0.900586 8.73893 0.796875 9 0.796875C9.26107 0.796875 9.51145 0.900586 9.69606 1.08519C9.88066 1.2698 9.98438 1.52018 9.98438 1.78125V8.01562H16.2188C16.4798 8.01562 16.7302 8.11934 16.9148 8.30394C17.0994 8.48855 17.2031 8.73893 17.2031 9Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default IconPlus;