import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
Chart.register(ArcElement);
// Chart.register(Legend);
// Chart.register(Title);
const PieChart = ({
  labels,
  data,
  backgroundColor,
  borderColor,
  shadowColor,
  chartName,
}) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: backgroundColor,
      },
    ],
  };
  const hexToRGBA = (hex) => {
    return (
      "rgba(" +
      (hex = hex.replace("#", ""))
        .match(new RegExp("(.{" + hex.length / 3 + "})", "g"))
        .map(function (l) {
          return parseInt(hex.length % 2 ? l + l : l, 16);
        })
        .concat(0.08)
        .join(",") +
      ")"
    );
  };
  return (
    <div
      className={`min-h-[220px] w-[100%] rounded-[10px] bg-[#ffffff] px-[16px] py-[16px] hover:!outline 
      hover:!outline-[3px] xs:min-h-[260px]
      md:w-[360px] md:px-[20px] md:py-[25px]
      lg:px-[25px]  lg:py-[25px]`}
      style={{
        outline: borderColor,
        boxShadow: `0px 30px 30px 4px ${hexToRGBA(shadowColor)}`,
        border: `1px solid ${borderColor}`,
      }}
    >
      <p
        className={
          "flex items-center text-center text-lg font-semibold not-italic leading-8 text-gray-900 md:text-xl lg:text-2xl"
        }
      >
        {chartName}
      </p>
      <div className={"mt-6 flex gap-4 md:gap-[20px] "}>
        <div
          className={
            "h-[120px] w-[120px] xs:h-[152px] xs:w-[152px] md:h-[160px] md:w-[160px] lg:h-[184px] lg:w-[184px]"
          }
        >
          <Pie data={chartData} />
        </div>
        <div>
          {chartData.datasets[0].data.map((data, index) => (
            <div
              className={
                "mb-[3px] flex flex-col gap-[3px] xs:mb-[8px] xs:gap-[8px]"
              }
              key={index}
            >
              <div className={"flex gap-[7px]"}>
                <span>
                  <svg
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="4.73635"
                      cy="5.5"
                      r="4.73635"
                      fill={backgroundColor[index]}
                    />
                  </svg>
                </span>
                <p
                  className="order-1 flex  flex-grow-0 
                   items-center break-all text-left
                text-[10px] font-light not-italic leading-3 text-gray-700 md:break-normal md:text-[12px]"
                >
                  {labels[index]}
                </p>
              </div>
              <p
                className={
                  "order-5 flex-none flex-grow-0 text-[14px] font-semibold not-italic leading-5 text-gray-900 lg:text-[17px]"
                }
              >
                {data}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PieChart;
