import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
};

const mediaQuerySlice = createSlice({
  name: "mediaQuery",
  initialState,
  reducers: {
    setIsMobile(state, action) {
      state.isMobile = action.payload;
      state.isDesktop = false;
      state.isTablet = false;
    },
    setIsTablet(state, action) {
      state.isTablet = action.payload;
      state.isDesktop = false;
      state.isMobile = false;
    },
    setIsDesktop(state, action) {
      state.isDesktop = action.payload;
      state.isMobile = false;
      state.isTablet = false;
    },
  },
});

export const { setIsMobile, setIsTablet, setIsDesktop } = mediaQuerySlice.actions;

export default mediaQuerySlice.reducer;
