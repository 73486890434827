import { useEffect } from "react";

const useDisableScroll = (scrollTop = false) => {
  useEffect(() => {
    const defaultOverflow = "auto";
    if (scrollTop) {
      document.documentElement.scrollTo(0, 0);
    }
    document.documentElement.style.overflow = "hidden";
    return () => {
      document.documentElement.style.overflow = defaultOverflow;
    };
  }, [scrollTop]);
};

export default useDisableScroll;
