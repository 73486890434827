import CarpenterCard from "./CarpenterCard";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCarpenters } from "../store/registry";
import NavigationDot from "./NavigationDot";
function ExpCarpenters() {
  const { isDesktop } = useSelector((state) => state.mediaQuery);

  let limit = !isDesktop ? 6 : 8;

  const [currentItemIndex, setCurrentItemIndex] = useState(1);
  const [lastItemIndex, setLastItemIndex] = useState(1);
  //TODO: import carpenters from the database
  const carpenters = useSelector((state) => state.registry.carpenters);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCarpenters());
  }, [dispatch]);

  // const [phoneSize, setPhoneSize] = useState(true);
  let carpentersCopy = useMemo(
    () =>
      [...carpenters]
        .sort((a, b) => {
          const lenA = a.project?.length || 0;
          const lenB = b.project?.length || 0;

          return lenA === 0 && lenB === 0
            ? 0
            : lenA === 0
            ? 1
            : lenB === 0
            ? -1
            : lenA < lenB
            ? 1
            : lenA > lenB
            ? -1
            : 0;
        })
        .filter((carpenter) => carpenter.userStatus === "APPROVED"),
    [carpenters]
  );
  if (!carpenters || carpenters.length === 0) {
    return null;
  }
  return (
    <div className="flex justify-center">
      <div className="exp-carpenters relative my-14 mb-24 flex max-w-6xl flex-col justify-center">
        <div className="absolute bottom-6 -right-[40px] z-[1] hidden flex-nowrap gap-3 md:flex lg:-right-0">
          <svg
            viewBox="0 0 74 72"
            className=" w-[60px] lg:w-[74px]"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              cx={59.5}
              cy={15}
              rx={15}
              ry={14.5}
              transform="rotate(90 59.5 15)"
              fill="#FFD66B"
            />
            <circle
              cx={59.5}
              cy={57.5}
              transform="rotate(90 59.5 57.5)"
              fill="#81C895"
              r={14.5}
            />
            <ellipse
              cx={14.5}
              cy={15}
              rx={15}
              ry={14.5}
              transform="rotate(90 14.5 15)"
              fill="#81C895"
            />
            <circle
              cx={14.5}
              cy={57.5}
              transform="rotate(90 14.5 57.5)"
              fill="#FFD66B"
              r={14.5}
            />
          </svg>
          <svg
            viewBox="0 0 74 72"
            className=" w-[60px] lg:w-[74px]"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              cx={59.5}
              cy={15}
              rx={15}
              ry={14.5}
              transform="rotate(90 59.5 15)"
              fill="#FFD66B"
            />
            <circle
              cx={59.5}
              cy={57.5}
              transform="rotate(90 59.5 57.5)"
              fill="#81C895"
              r={14.5}
            />
            <ellipse
              cx={14.5}
              cy={15}
              rx={15}
              ry={14.5}
              transform="rotate(90 14.5 15)"
              fill="#81C895"
            />
            <circle
              cx={14.5}
              cy={57.5}
              transform="rotate(90 14.5 57.5)"
              fill="#FFD66B"
              r={14.5}
            />
          </svg>

          <svg
            className="hidden lg:block "
            width={74}
            height={72}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              cx={59.5}
              cy={15}
              rx={15}
              ry={14.5}
              transform="rotate(90 59.5 15)"
              fill="#FFD66B"
            />
            <circle
              cx={59.5}
              cy={57.5}
              transform="rotate(90 59.5 57.5)"
              fill="#81C895"
              r={14.5}
            />
            <ellipse
              cx={14.5}
              cy={15}
              rx={15}
              ry={14.5}
              transform="rotate(90 14.5 15)"
              fill="#81C895"
            />
            <circle
              cx={14.5}
              cy={57.5}
              transform="rotate(90 14.5 57.5)"
              fill="#FFD66B"
              r={14.5}
            />
          </svg>
        </div>
        <h3 className="mb-14 flex justify-center text-center text-2xl font-bold  md:text-4xl lg:text-5xl ">
          Experienced carpenters
        </h3>
        {/* TODO: When we get to the phone size */}
        {/* {phoneSize&&<Carousel carpenters={carpenters} />} */}
        <ul
          className={` text-secondary-dark z-[2] mb-8 hidden grid-rows-2 gap-6 self-center
           md:grid md:grid-cols-3 
           xl:grid-cols-4`}
        >
          {carpentersCopy.slice(0, limit).map((carpenter, index) => {
            return <CarpenterCard key={index} carpenter={carpenter} />;
          })}
        </ul>
        <div className="flex h-auto w-screen flex-col items-center gap-[16px]  overflow-hidden  md:hidden">
          <ul className="relative h-[230px] w-full ">
            {carpentersCopy.slice(0, limit).map((carpenter, index) => {
              return (
                <div
                  key={index}
                  className=" absolute top-0 left-0 h-full w-full"
                >
                  <div className="absolute left-[calc(-210px*0.9)] xs:left-[calc(-260px*0.7)]  ">
                    <CSSTransition
                      unmountOnExit
                      mountOnEnter
                      key={carpenter.id}
                      in={index === currentItemIndex - 1}
                      timeout={500}
                      classNames={
                        currentItemIndex > lastItemIndex
                          ? "next-carpenter"
                          : "prev-carpenter"
                      }
                    >
                      <CarpenterCard key={carpenter.id} carpenter={carpenter} />
                    </CSSTransition>
                  </div>
                  <div
                    key={index}
                    className="absolute left-[calc(50vw-105px)] h-fit xs:left-[calc(50vw-130px)]"
                  >
                    <CSSTransition
                      unmountOnExit
                      mountOnEnter
                      onEntered={() => {
                        setLastItemIndex(index);
                      }}
                      key={carpenter.id}
                      in={index === currentItemIndex}
                      timeout={500}
                      classNames={
                        currentItemIndex > lastItemIndex
                          ? "next-carpenter"
                          : "prev-carpenter"
                      }
                    >
                      <CarpenterCard
                        className={
                          "border-green-200 drop-shadow-[0_2px_36px_rgba(129,200,149,.1)]"
                        }
                        key={carpenter.id}
                        carpenter={carpenter}
                      />
                    </CSSTransition>
                  </div>
                  <div
                    key={index + 1}
                    className="absolute right-[calc(-210px*0.9)] h-fit xs:right-[calc(-260px*0.7)]"
                  >
                    <CSSTransition
                      unmountOnExit
                      mountOnEnter
                      key={carpenter.id}
                      in={index === currentItemIndex + 1}
                      timeout={500}
                      classNames={
                        currentItemIndex > lastItemIndex
                          ? "next-carpenter"
                          : "prev-carpenter"
                      }
                    >
                      <CarpenterCard key={carpenter.id} carpenter={carpenter} />
                    </CSSTransition>
                  </div>
                </div>
              );
            })}
          </ul>
          <div className="flex h-[20px] items-center gap-2">
            {carpentersCopy.slice(0, limit).map((car, index) => {
              return (
                <NavigationDot
                  key={index}
                  onClick={() => setCurrentItemIndex(index)}
                  color={currentItemIndex === index ? "#16A34A" : "#A9B2B9"}
                />
              );
            })}
          </div>
        </div>
        <div className=" mt-[43px] flex flex-nowrap content-end justify-center font-medium text-primary-60 md:mt-0 ">
          <Link className="relative text-[14px] md:text-xl" to="/registry">
            View all carpenters
            <span className=" translate-y-50% material-icons absolute bottom-0  ml-1 transform">
              arrow_forward
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ExpCarpenters;
