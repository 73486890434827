import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Parishes } from "../models";
import {
  getACarpenterById,
  getAllCarpenters,
  updateCarpenter,
  getCreatedDate,
  getUpdatedDate,
} from "../utils/aws.services";
import { UserStatus } from "../models";
const initialState = {
  carpenters: [],
  carpenterByParish: {
    SAINT_JOHN: 0,
    SAINT_ANDREW: 0,
    SAINT_PETER: 0,
    SAINT_JOSEPH: 0,
    SAINT_GEORGE: 0,
    SAINT_PAUL: 0,
    SAINT_DAVID: 0,
    SAINT_PATRICK: 0,
    SAINT_LUKE: 0,
    SAINT_MARK: 0,
  },
  createdDate: "",
  latestApprovedTime: "",
  latestPendingTime: "",
  latestDeletedTime: "",
};

const registrySlice = createSlice({
  name: "registry",
  initialState,
  reducers: {
    setCarpenters(state, action) {
      state.carpenters = action.payload;
    },
    addACarpenter(state, action) {
      state.carpenters = [...state.carpenters, action.payload];
    },
    setCarpenterByParish(state, action) {
      state.carpenterByParish = action.payload;
    },
    updateACarpenter(state, action) {
      const index = state.carpenters.findIndex(
        (carpenter) => carpenter.id === action.payload.id
      );
      state.carpenters[index] = action.payload;
    },
    approveACapenter(state, action) {
      if (state.carpenters.length > 0) {
        const currentCarpenterIndex = state.carpenters.findIndex(
          (carpenter) => carpenter.id === action.payload.id
        );
        state.carpenters[currentCarpenterIndex].userStatus = "APPROVED";
      }
    },
    deleteACapenter(state, action) {
      if (state.carpenters.length > 0) {
        const currentCarpenterIndex = state.carpenters.findIndex(
          (carpenter) => carpenter.id === action.payload.id
        );
        state.carpenters[currentCarpenterIndex].userStatus = "SUSPENDED";
      }
    },
    revokeACapenter(state, action) {
      if (state.carpenters.length > 0) {
        const currentCarpenterIndex = state.carpenters.findIndex(
          (carpenter) => carpenter.id === action.payload.id
        );
        state.carpenters[currentCarpenterIndex].userStatus = "PENDING";
      }
    },
    editACarpenter(state, action) {
      let carpenterId = action.payload.id;
      let carpenterIndex = state.carpenters.findIndex(
        (carpenter) => carpenter.id === carpenterId
      );
      state.carpenters[carpenterIndex] = action.payload;
    },
    setCreatedTime(state, action) {
      state.createdDate = action.payload;
    },
    setApproveTime(state, action) {
      state.latestApprovedTime = action.payload;
    },
    setPendingTime(state, action) {
      state.latestPendingTime = action.payload;
    },
    setDeletedTime(state, action) {
      state.latestDeletedTime = action.payload;
    },
  },
});

export default registrySlice.reducer;
export const registryActions = registrySlice.actions;

export const editACarpenter = (
  id,
  currentVersion,
  userData,
  newData,
  formValidation
) => {
  return async (dispatch, getState) => {
    try {
      await updateCarpenter(id, currentVersion, newData);
      dispatch(registryActions.editACarpenter({ ...userData, ...newData }));
      toast.success("Updated", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCarpenters = () => {
  return async (dispatch, getState) => {
    // const isAdmin = getState().auth.isAdmin;
    //HTTP request to get carpenter list
    const carpenterByParish = {
      SAINT_JOHN: 0,
      SAINT_ANDREW: 0,
      SAINT_PETER: 0,
      SAINT_JOSEPH: 0,
      SAINT_GEORGE: 0,
      SAINT_PAUL: 0,
      SAINT_PATRICK: 0,
      SAINT_LUKE: 0,
      SAINT_MARK: 0,
    };
    const parishes = Object.keys(Parishes);
    try {
      // let carpenters;
      const carpenters = await getAllCarpenters();
      if (carpenters.length > 0) {
        const allParishes = carpenters
          .filter((carpenter) => carpenter.userStatus === "APPROVED")
          .map((carpenter) => carpenter.parish);
        parishes.forEach((parish) => {
          const currentParishCount = allParishes.filter(
            (item) => item === parish
          ).length;
          carpenterByParish[parish] = currentParishCount;
        });
        dispatch(registryActions.setCarpenterByParish(carpenterByParish));
      }

      dispatch(registryActions.setCarpenters(carpenters));
    } catch (error) {
      toast.error("Something wrong happened, please try again later", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};
export const getCreatedTime = () => {
  return async (dispatch, getState) => {
    try {
      const createdTime = await getCreatedDate("ASC", "APPROVED");
      dispatch(registryActions.setCreatedTime(createdTime));
    } catch (error) {
      console.log(error);
    }
  };
};
export const getApprovedTime = () => {
  return async (dispatch, getState) => {
    const lastApprovedTime = getState().registry.latestApprovedTime;
    let latestApprovedTime;
    try {
      latestApprovedTime = await getUpdatedDate("DESC", "APPROVED");
      dispatch(registryActions.setApproveTime(latestApprovedTime));
    } catch (error) {
      throw error;
    }
    if (lastApprovedTime) {
      if (
        new Date(lastApprovedTime).getTime() <
        new Date(latestApprovedTime).getTime()
      ) {
        try {
          await dispatch(getCarpenters());
        } catch (error) {
          throw error;
        }
      }
    }
  };
};

export const getPendingTime = () => {
  return async (dispatch, getState) => {
    const lastPendingTime = getState().registry.latestPendingTime;
    let latestPendingTime;
    try {
      latestPendingTime = await getUpdatedDate("DESC", "PENDING");
      dispatch(registryActions.setPendingTime(lastPendingTime));
    } catch (error) {
      throw error;
    }
    if (lastPendingTime) {
      if (
        new Date(lastPendingTime).getTime() <
        new Date(latestPendingTime).getTime()
      ) {
        try {
          await dispatch(getCarpenters());
        } catch (error) {
          throw error;
        }
      }
    }
  };
};

export const getDeletedTime = () => {
  return async (dispatch, getState) => {
    const lastDeletedTime = getState().registry.latestDeletedTime;
    let latestDeletedTime;
    try {
      latestDeletedTime = await getUpdatedDate("DESC", "SUSPENDED");
      dispatch(registryActions.setDeletedTime(latestDeletedTime));
    } catch (error) {
      throw error;
    }
    if (lastDeletedTime) {
      if (
        new Date(lastDeletedTime).getTime() <
        new Date(latestDeletedTime).getTime()
      ) {
        try {
          await dispatch(getCarpenters());
        } catch (error) {
          throw error;
        }
      }
    }
  };
};

export const getACarpenter = (id) => {
  return async (dispatch, getState) => {
    //HTTP request to get carpenter info
    const carpenters = getState().registry.carpenters;
    const currentCarpenter = carpenters.find(
      (carpenter) => carpenter.id === id
    );
    if (!currentCarpenter) {
      let carpenter;
      try {
        carpenter = await getACarpenterById(id);
      } catch (error) {
        return toast.error("Something wrong happened, please try again.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      dispatch(registryActions.addACarpenter(carpenter));
    }
  };
};

export const changeCarpenterStatus = (carpenter, action) => {
  return async (dispatch, getState) => {
    const { id } = carpenter;
    const { CarpenterHasManyProjects, ...carpenterData } = carpenter;
    const actionLableHandler = (action) => {
      const upperCaseFirstCharacter =
        action.charAt(0).toUpperCase() + action.slice(1);
      const deletedLastCharacter = upperCaseFirstCharacter.slice(0, -1);
      return deletedLastCharacter + "ed";
    };
    //Http request to the server, if server response without error, proceed to change the state
    try {
      //If server response with a 200 response
      switch (action) {
        case "revoke":
          try {
            await updateCarpenter(id, carpenter._version, {
              userStatus: UserStatus.PENDING,
            });
            dispatch(registryActions.revokeACapenter({ id }));
          } catch (error) {
            throw error;
          }
          break;
        case "delete":
          try {
            await updateCarpenter(id, carpenter._version, {
              userStatus: UserStatus.SUSPENDED,
            });
            dispatch(registryActions.deleteACapenter({ id }));
          } catch (error) {
            throw error;
          }

          break;
        default:
          try {
            await updateCarpenter(id, carpenter._version, {
              userStatus: UserStatus.APPROVED,
            });
            dispatch(registryActions.approveACapenter({ id }));
          } catch (error) {
            throw error;
          }

          break;
      }

      toast.success(
        <>
          <p className="text-[20px] font-medium leading-[28px] text-dark-primary">{`${actionLableHandler(
            action
          )} successfully`}</p>{" "}
          <p className="text-[12px] leading-[14px] text-dark-tertiary">Today</p>
        </>,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          icon: (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="2"
                y="2"
                width="20"
                height="20"
                rx="5"
                stroke="#15803D"
                strokeWidth="1.5"
              />
              <path
                d="M9.5 11.5L11.5 13.5L15.5 9.5"
                stroke="#15803D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),

          className: " !font-sans !bg-success-20 ",
        }
      );
    } catch (error) {
      toast.error("Something wrong happened, please try again.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};
