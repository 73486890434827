import { Routes, Route, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { lazy, Suspense } from "react";
import Spinner from "./components/Spinner";
import RootLayer from "./pages/RootLayer";
import NotFound from "./pages/NotFound";
import React from "react";
import useWindowSize from "./hooks/useWindowSize";
import { Amplify } from "aws-amplify";
import "./App.css";
import awsExports from "./aws-exports";
import { getCreatedTime } from "./store/registry";
import { useDispatch } from "react-redux";
import { fetchServices } from "./store/service";
import useScrollTop from "./hooks/useScrollTop";
import PrivateRoutes from "./utils/privateRoutes";
import { getCarpenters } from "./store/registry";
import { fetchInformation } from "./store/auth";
import { Auth } from "aws-amplify";
import About from "./pages/About";

Amplify.configure(awsExports);
const Registry = lazy(() => import("./pages/Registry"));
const RegistrationForm = lazy(() => import("./pages/RegistrationForm"));
const Profile = lazy(() => import("./pages/Profile"));
const LogIn = lazy(() => import("./pages/LogIn"));
const ProfileEdit = lazy(() => import("./pages/ProfileEdit"));
const Project = lazy(() => import("./pages/Project"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ForgotPasswordConfirm = lazy(() =>
  import("./pages/ForgotPasswordConfirm")
);
const AuthLayer = lazy(() => import("./pages/AuthLayer"));
export default function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const checkUser = async () => {
      await Auth.currentAuthenticatedUser()
        .then((user) => {
          dispatch(fetchInformation(user));
        })
        .catch((err) => console.warn(err));
    };
    const getData = async () => {
      await Promise.all([
        dispatch(getCarpenters()),
        dispatch(getCreatedTime()),
        dispatch(fetchServices()),
      ]);
      await checkUser();
    };
    getData();
  }, [dispatch]);

  useWindowSize();
  useScrollTop();
  return (
    <Routes>
      <Route path="/" element={<RootLayer />}>
        <Route
          path="/"
          element={
            // <Suspense fallback={<Spinner />}>
            <About />
          }
        />
        <Route
          path="registry"
          element={
            <Suspense fallback={<Spinner />}>
              <Registry />
            </Suspense>
          }
        />
        <Route
          path="auth"
          element={
            <Suspense fallback={<Spinner />}>
              <AuthLayer />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<Spinner />}>
                <LogIn />
              </Suspense>
            }
          />
          <Route
            path="login"
            element={
              <Suspense fallback={<Spinner />}>
                <LogIn />
              </Suspense>
            }
          />
          <Route
            path="forgot-password"
            element={
              <Suspense fallback={<Spinner />}>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path="confirm-password"
            element={
              <Suspense fallback={<Spinner />}>
                <ForgotPasswordConfirm />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="registry/:cid"
          element={
            <Suspense fallback={<Spinner />}>
              <Profile />
            </Suspense>
          }
        />
        <Route element={<PrivateRoutes />}>
          <Route
            path="registry/:cid/edit-profile"
            element={
              <Suspense fallback={<Spinner />}>
                <ProfileEdit />
              </Suspense>
            }
          />
          <Route
            path="registry/:cid/:id"
            element={
              <Suspense fallback={<Spinner />}>
                <Project />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="join"
          element={
            <Suspense fallback={<Spinner />}>
              <RegistrationForm />
            </Suspense>
          }
        />
        <Route path="not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/not-found" replace />} />
      </Route>
    </Routes>
  );
}
