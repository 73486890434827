import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const PrivateRoutes = () => {
  const user = useSelector((state) => state.auth.user);
  const isLogin = useSelector((state) => state.auth.isLogin);
  const { cid } = useParams();
  let auth = isLogin && cid === user.username;

  return auth ? <Outlet /> : <Navigate to="/auth/login" replace />;
};

export default PrivateRoutes;