import useDisableScroll from "./../hooks/useDisableScroll";
const Spinner = () => {
  useDisableScroll();
  return (
    <>
      <div className="fixed top-0 left-0  h-screen w-screen bg-modal-backdrop"></div>
      <div className=" flex  h-[600px] items-center justify-center ">
        <span className="spinner"></span>
      </div>
    </>
  );
};

export default Spinner;
