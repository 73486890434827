import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { Parishes } from "../models";
/**
 *
 * @returns An array of all carpenters
 *
 * @example const carpenters = await getAllCarpenters();
 *
 */
export const getAllCarpenters = async () => {
  try {
    const resp = await API.graphql({
      query: queries.listCarpenters,
    });
    const data = resp.data.listCarpenters.items;
    const mappedData = data.map((data) => ({
      ...data,
      project: data.CarpenterHasManyProjects.items.map((item) => item.id),
    }));
    return mappedData;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const checkEmailExisted = async (email) => {
  try {
    const resp = await API.graphql({
      query: `query checkExistedEmail {
        listCarpenters(filter: {_deleted: {ne: true}, email: {eq: "${email}"}}) {
          items {
            address
            email
            firstName
            id
          }
        }
      }
      `,
    });

    const emailExisted = resp.data.listCarpenters.items.length > 0;

    return emailExisted;
  } catch (err) {
    throw err;
  }
};

export const getACarpenterById = async (id) => {
  try {
    const resp = await API.graphql({
      query: queries.getCarpenters,
      variables: { id },
    });

    const data = resp.data.getCarpenters;
    if (!data) {
      throw new Error("User is not found");
    }
    return {
      ...resp.data.getCarpenters,
      project: resp.data.getCarpenters.CarpenterHasManyProjects.items.map(
        (item) => item.id
      ),
    };
  } catch (err) {
    throw err;
  }
};

/**
 *
 * @returns An array of objects that contains the parish name as well as the number of carpenters in that parish
 *
 * @example const parishes = await getNumberOfCarpentersByParishes();
 *
 */
export const getNumberOfCarpentersByParishes = async () => {
  try {
    const resp = await API.graphql({
      query: queries.listCarpenters,
    });
    const carpenters = resp.data.listCarpenters.items;
    // make a list of parishes based on the enum
    const parishes = Object.keys(Parishes).map((parish) => {
      return {
        parish: parish,
        count: 0,
      };
    });
    // go through the list of carpenters and increment the count of carpenters by parishes
    carpenters.forEach((carpenter) => {
      const currentParish = carpenter.parish;
      if (!currentParish) return;
      const indexOfParish = parishes.findIndex(
        (item) => item.parish === currentParish
      );
      parishes[indexOfParish].count++;
    });
    return parishes;
  } catch (err) {
    console.error(err);
  }
};

/**
 *
 * @param {Object} carpenterData - An object that represents the carpenter data
 *
 * @returns {Object} The response from the call
 *
 * @example await createCarpenter({
 *    firstName: 'John',
 *    lastName: 'Doe',
 *    phone: '123456789',
 *    address: '123 Fake Street',
 *    email: 'john@example.com',
 *    project: ['3a920543-06aa-4161-b532-18fa6b020f16'],
 *    services: ['Framer', 'Roofer', 'Drywall']
 *    rebuild: true,
 *    beAMentor: false,
 *    beMentored: true,
 *    isApproved: false,
 *    parish: 'SAINT_JOHN'
 * })
 *
 */
export const createCarpenter = async (carpenterData) => {
  try {
    const resp = await API.graphql({
      query: mutations.createCarpenters,
      variables: {
        input: carpenterData,
      },
    });
    return resp;
  } catch (err) {
    console.error(err);
  }
};

/**
 *
 * @param {String} id  The carpenter's unique ID
 * @param {Int} version -The value of the _version property in the carpenter object, this field is needed for mutations since conflict detection is enabled
 * @see https://docs.amplify.aws/cli-legacy/graphql-transformer/versioned/#usage
 * @param {Object} updates  An object containing the fields that you want to update
 *
 * @returns {Object} The response from the call
 *
 * @example
 *    - update the first name and the account status of the carpenter
 *    await updateCarpenter('<the id of the carpenter>', 3, {
 *       firstName: 'John',
 *       isApproved: true
 *    })
 */
export const updateCarpenter = async (id, version, updates) => {
  try {
    const resp = await API.graphql({
      query: mutations.updateCarpenters,
      variables: {
        input: {
          id,
          _version: version,
          ...updates,
        },
      },
    });
    return resp;
  } catch (err) {
    console.error(err);
  }
};

/**
 *
 * @param {String} id The carpenter's unique ID
 * @param {Int} version -The value of the _version property in the carpenter object, this field is needed for mutations since conflict detection is enabled
 * @see https://docs.amplify.aws/cli-legacy/graphql-transformer/versioned/#usage
 *
 * @returns {Object} The response from the call
 * @example await deleteCarpenter('<the id of the carpenter>', 2)
 *
 */

export const deleteCarpenter = async (id, version) => {
  try {
    const resp = await API.graphql({
      query: mutations.deleteCarpenters,
      variables: {
        input: { id, _version: version },
      },
    });
    return resp;
  } catch (err) {
    console.error(err);
  }
};

/**
 *
 * @param {Object} projectData The object that represents the project data
 *
 * @returns {Object} The response from the call
 *
 * @example
 *
 * await createProject({
 *    name: 'House',
 *    description:"The description",
 *    images: ['<project image url>','<project image url>'],
 *    ownerId: '<id of the carpenter the project belongs to>,
 * })
 *
 */
export const createProject = async (projectData) => {
  try {
    const resp = await API.graphql({
      query: mutations.createProjects,
      variables: {
        input: projectData,
      },
    });
    return resp;
  } catch (err) {
    console.error(err);
  }
};

/**
 *
 * @param {String} id  The project's unique ID
 * @param {Int} version -The value of the _version property in the project object, this field is needed for mutations since conflict detection is enabled
 * @see https://docs.amplify.aws/cli-legacy/graphql-transformer/versioned/#usage
 * @param {Object} updates  An object containing the fields that you want to update
 *
 * @returns {Object} The response from the call
 *
 * @example
 *    - update the name and the images of the project
 *    await updateCarpenter('<the id of the project>', 3, {
 *       name: 'A big house',
 *
 *       images: ['<project image url>']
 *    })
 */

export const updateProject = async (id, version, updates) => {
  try {
    const resp = await API.graphql({
      query: mutations.updateProjects,
      variables: {
        input: {
          id,
          _version: version,
          ...updates,
        },
      },
    });
    return resp;
  } catch (err) {
    console.error(err);
  }
};
/**
 *
 * @param {String} id The project's unique ID
 * @param {Int} version -The value of the _version property in the project object, this field is needed for mutations since conflict detection is enabled
 * @see https://docs.amplify.aws/cli-legacy/graphql-transformer/versioned/#usage
 *
 * @returns {Object} The response from the call
 * @example await deleteProject('<the id of the project>', 2)
 *
 */

export const deleteProject = async (id, version) => {
  try {
    const resp = await API.graphql({
      query: mutations.deleteProjects,
      variables: {
        input: { id, _version: version },
      },
    });
    return resp;
  } catch (err) {
    console.error(err);
  }
};
/**
 *
 * @returns An array of all projects
 *
 * @example const projects = await getAllProjects();
 *
 */
export const getAllProjects = async () => {
  try {
    const resp = await API.graphql({
      query: queries.listProjects,
    });
    return resp.data.listProjects.items;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getAProjectById = async (id) => {
  try {
    const resp = await API.graphql({
      query: queries.getProjects,
      variables: { id },
    });

    return resp.data.getProjects;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getAllServices = async () => {
  try {
    const resp = await API.graphql({
      query: queries.listServices,
    });
    const data = resp.data.listServices.items;
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getCreatedDate = async (sortDirection, userStatus) => {
  try {
    const res = await API.graphql({
      query: queries.sortByCreatedDate,
      variables: { sortDirection, userStatus },
    });
    const data = res.data.sortByCreatedDate.items;

    if (data.length > 0) {
      return data[0].createdAt;
    }
    return null;
  } catch (error) {
    console.error(error);
  }
};

export const getUpdatedDate = async (sortDirection, userStatus) => {
  try {
    const res = await API.graphql({
      query: queries.sortByUpdatedDate,
      variables: { sortDirection, userStatus },
    });
    const data = res.data.sortByUpdatedDate.items;
    if (data.length > 0) {
      return data[0].updatedAt;
    }
    return null;
  } catch (error) {
    console.error(error);
  }
};
