import {useMemo } from "react";
import PropTypes from "prop-types";
import IconPlus from "./icons/IconPlus";
import IconDownload from "./icons/IconDownload";
import IconEdit from "./icons/IconEdit";
import IconCloseBorderless from "./icons/IconCloseBorderless";
import { useSelector } from "react-redux";

function Button(props) {
  const { isDesktop } = useSelector((state) => state.mediaQuery);
  const { isTablet } = useSelector((state) => state.mediaQuery);

  const {
    label = "Button",
    buttonType = "primary",
    buttonSize = `${isDesktop ? "large" : isTablet ? "medium" : "small"}`,
    iconType = "none",
    iconPosition = "none",
    extraClasses = "",
    type = "button",
    ...otherProps
  } = props;

  const BUTTON_TYPES = {
    primary: "primary",
    secondary: "secondary",
    tertiary: "tertiary",
  };

  const BUTTON_SIZES = {
    large: "large",
    medium: "medium",
    small: "small",
  };

  const BUTTON_SIZE_CLASSES = {
    large: "min-w-[250px] min-h-[60px]",
    medium: "min-w-[170px] min-h-[50px]",
    small: "min-w-[148px] min-h-[43px]",
    largeIconOnly: "min-w-[110px] min-h-[60px]",
    mediumIconOnly: "min-w-[67px] min-h-[50px]",
    smallIconOnly: "min-w-[65px] min-h-[43px]",
  };

  const commonClasses =
    "items-center justify-center flex block text-xl border-none text-xl leading-7 rounded-lg font-sans font-medium mx-auto";

  const BUTTON_CLASSES = {
    primary:
      "disabled:shadow-none disabled:bg-[#ADB1B5] active:bg-primary-60 hover:bg-primary-80 focus:bg-primary-80 active:shadow-[inset_0px_0px_0px_1px_#14532D] bg-primary-60 text-light-primary",
    secondary:
      "bg-transparent text-primary-60 hover:text-primary-80 focus:text-primary-80 active:text-primary-100 disabled:text-light-tertiary disabled:shadow-[inset_0px_0px_0px_1px_#434D56] shadow-[inset_0px_0px_0px_1px_#166543]",
    tertiary:
      "bg-transparent disabled:text-light-tertiary text-primary-60 hover:text-primary-80 focus:text-primary-80 active:text-primary-100",
  };

  const ICON_TYPES = {
    none: "none",
    plus: "plus",
    edit: "edit",
    download: "download",
    closeBorderless: "closeBorderless",
  };

  const ICON_POSITIONS = {
    center: "center",
    left: "left",
    right: "right",
  };

  const ICON_CLASSES = {
    iconLeft: "p-0 m-0 mr-2",
    iconRight: "p-0 m-0 ml-2",
  };

  const classes = useMemo(() => {
    switch (buttonType) {
      case BUTTON_TYPES.secondary:
        return BUTTON_CLASSES.secondary;
      case BUTTON_TYPES.tertiary:
        return BUTTON_CLASSES.tertiary;
      default:
        return BUTTON_CLASSES.primary;
    }
  }, [
    BUTTON_CLASSES.primary,
    BUTTON_CLASSES.secondary,
    BUTTON_CLASSES.tertiary,
    BUTTON_TYPES.secondary,
    BUTTON_TYPES.tertiary,
    buttonType,
  ]);

  const sizeClasses = useMemo(() => {
    if (!iconPosition !== ICON_POSITIONS.center) {
      switch (buttonSize) {
        case BUTTON_SIZES.medium:
          return BUTTON_SIZE_CLASSES.medium;

        case BUTTON_SIZES.small:
          return BUTTON_SIZE_CLASSES.small;
        default:
          return BUTTON_SIZE_CLASSES.large;
      }
    } else {
      switch (buttonSize) {
        case BUTTON_SIZES.medium:
          return BUTTON_SIZE_CLASSES.mediumIconOnly;

        case BUTTON_SIZES.small:
          return BUTTON_SIZE_CLASSES.smallIconOnly;

        default:
          return BUTTON_SIZE_CLASSES.largeIconOnly;
      }
    }
  }, [
    BUTTON_SIZES.medium,
    BUTTON_SIZES.small,
    BUTTON_SIZE_CLASSES.large,
    BUTTON_SIZE_CLASSES.largeIconOnly,
    BUTTON_SIZE_CLASSES.medium,
    BUTTON_SIZE_CLASSES.mediumIconOnly,
    BUTTON_SIZE_CLASSES.small,
    BUTTON_SIZE_CLASSES.smallIconOnly,
    ICON_POSITIONS.center,
    buttonSize,
    iconPosition,
  ]);

  const iconClasses = useMemo(() => {
    switch (iconPosition) {
      case ICON_POSITIONS.left:
        return ICON_CLASSES.iconLeft;

      case ICON_POSITIONS.right:
        return ICON_CLASSES.iconRight;

      default:
        return ICON_CLASSES.iconRight;
    }
  }, [
    ICON_CLASSES.iconLeft,
    ICON_CLASSES.iconRight,
    ICON_POSITIONS.left,
    ICON_POSITIONS.right,
    iconPosition,
  ]);

  // Adding extra classes at the end so that style rewrite is possible
  const className = useMemo(
    () =>
      commonClasses + " " + classes + " " + sizeClasses + " " + extraClasses,
    [classes, extraClasses, sizeClasses]
  );

  const prepButtonContents = () => {
    if (iconType === "none") {
      return <>{label}</>;
    }

    if (iconType !== "none" && iconPosition === "center") {
      return <span className={iconClasses}>{getIconByType()}</span>;
    }

    if (iconType !== "none" && iconPosition === "left") {
      return (
        <>
          <span className={iconClasses}>{getIconByType()}</span>
          {label}
        </>
      );
    }

    if (
      iconType !== "none" &&
      (iconPosition === "right" || iconPosition === "none")
    ) {
      return (
        <>
          {label}
          <span className={iconClasses}>{getIconByType()}</span>
        </>
      );
    }
  };

  const getIconByType = () => {
    switch (iconType) {
      case ICON_TYPES.download:
        return <IconDownload />;
      case ICON_TYPES.plus:
        return <IconPlus />;
      case ICON_TYPES.edit:
        return <IconEdit />;
      case ICON_TYPES.closeBorderless:
        return <IconCloseBorderless />;
      default:
        return;
    }
  };

  return (
    <button className={className} type={type} {...otherProps}>
      {prepButtonContents()}
    </button>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  buttonType: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  buttonSize: PropTypes.oneOf(["large", "medium", "small"]),
  iconType: PropTypes.oneOf([
    "none",
    "plus",
    "download",
    "edit",
    "closeBorderless",
  ]),
  iconPosition: PropTypes.oneOf(["none", "left", "center", "right"]),
  extraClasses: PropTypes.string,
  type: PropTypes.string,
};

export default Button;
