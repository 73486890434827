import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import registryReducer from "./registry";
import projectReducer from "./project";
import serviceReducer from "./service";
import mediaQuerySliceReducer from "./mediaQuery";

const store = configureStore({
  reducer: {
    auth: authReducer,
    registry: registryReducer,
    mediaQuery: mediaQuerySliceReducer,
    project: projectReducer,
    service: serviceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
