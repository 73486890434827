import { useSelector } from "react-redux";
import Button from "./Button";
import { CSVLink } from "react-csv";
import { useRef } from "react";
const header = [
  { label: "First Name", key: "firstName" },
  { label: "Last name", key: "lastName" },
  { label: "Business name", key: "businessName" },
  { label: "Business address", key: "address" },
  { label: "Phone Number", key: "phone" },
  { label: "Email", key: "email" },
  { label: "Parish", key: "parish" },
];
function BannerDetailedReport() {
  const carpenters = useSelector((state) => state.registry.carpenters);
  const csvRef = useRef(null);
  const exportData =
    carpenters.length > 0
      ? carpenters.filter((carpenter) => carpenter.userStatus === "APPROVED")
      : [];

  return (
    <div
      className="mx-auto mb-[100px] rounded-none bg-banner-detailed-report bg-cover  
    bg-no-repeat md:h-full md:rounded-xl lg:rounded-none"
    >
      <div
        className=" relative mx-auto flex flex-wrap items-center justify-center gap-[36px] rounded-xl px-[18px] py-[48px] md:p-[48px] 
      lg:max-w-[1440px] lg:rounded-none lg:px-[150px] lg:py-[67px]"
      >
        <p className="inline-block max-w-[708px] text-light-primary  lg:text-2xl lg:leading-10">
          <b>Detailed report</b> lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Donec suscipit quis urna vitae lobortis. Vestibulum
          malesuada ligula dui. Maecenas a urna massa.
        </p>
        <Button
          buttonType="primary"
          iconType="download"
          iconPosition="right"
          label="Download"
          onClick={() => {
            csvRef.current.link.click();
          }}
          extraClasses="h-[50px] md:h-[60px] lg:h-[70px] px-[24px] md:px-[32px] lg:px-[40px] lg:py-[16px] lg:text-xl"
        />
        <CSVLink
          ref={csvRef}
          className="hidden"
          filename="Carpenters-data.csv"
          data={exportData}
          headers={header}
        />

        <svg
          className="absolute top-[-10%] right-0 md:top-[-5%] lg:top-[10%] lg:right-[8%]"
          width="64"
          height="80"
          viewBox="0 0 64 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="fill-[#4FC57E] lg:fill-[#FFD66B]"
            d="M53 22C53.7174 22 54.3053 21.4724 54.4049 20.7258C55.3913 14.076 56.298 13.1502 62.6947 12.4235C63.4321 12.3339 64 11.7167 64 11C64 10.2733 63.442 9.67602 62.7047 9.56652C56.3478 8.68054 55.5607 7.90407 54.4049 1.26425C54.2754 0.527602 53.7074 0 53 0C52.2726 0 51.6947 0.527602 51.5752 1.27421C50.6087 7.91403 49.702 8.83982 43.3152 9.56652C42.558 9.66606 42 10.2633 42 11C42 11.7167 42.538 12.314 43.2953 12.4235C49.6621 13.3294 50.4393 14.0959 51.5752 20.7357C51.7246 21.4824 52.3025 22 53 22Z"
          />
          <path
            className="fill-[#4FC57E] lg:fill-[#FFD66B]"
            d="M21 80C22.3696 80 23.4918 78.9928 23.6821 77.5674C25.5652 64.8724 27.2962 63.105 39.5082 61.7176C40.9158 61.5466 42 60.3683 42 59C42 57.6127 40.9348 56.4724 39.5272 56.2633C27.3913 54.5719 25.8886 53.0896 23.6821 40.4136C23.4348 39.0072 22.3505 38 21 38C19.6114 38 18.5082 39.0072 18.2799 40.4326C16.4348 53.1086 14.7038 54.876 2.51087 56.2633C1.06522 56.4534 0 57.5937 0 59C0 60.3683 1.02717 61.5086 2.47283 61.7176C14.6277 63.4471 16.1114 64.9104 18.2799 77.5864C18.5652 79.0118 19.6685 80 21 80Z"
          />
        </svg>
      </div>
    </div>
  );
}

export default BannerDetailedReport;
