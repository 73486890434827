const NavigationDot = ({ color, onClick }) => {
  return (
    <span className="cursor-pointer shadow-md" onClick={onClick}>
      <svg
        width="9"
        height="9"
        viewBox="0 0 9 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4.5" cy="4.5" r="4.5" fill={color} />
      </svg>
    </span>
  );
};
export default NavigationDot;
