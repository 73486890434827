function YouTubeEmbed() {
  return (
    <iframe
      className="z-10 h-[250px] w-full rounded-[20px]   xs:h-[350px] xs:w-[90%]  md:h-[308px] md:w-[551px] "
      width="100%"
      src="https://www.youtube.com/embed/vTFVg6I2wNI"
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share allowfullscreen"
    />
  );
}

export default YouTubeEmbed;
