import { useSelector } from "react-redux";

import ExpCarpenters from "../components/ExpCarpenters";
import VideoEmbed from "../components/VideoEmbed";
import BannerHero from "../components/BannerHero";
import BannerDetailedReport from "../components/BannerDetailedReport";
import StatisticsMap from "../components/StatisticsMap";
import StatisticsDiagrams from "../components/StatisticsDiagrams";

const DIAGRAMDATA = {
  expertise: {
    carpenters: 400,
    joiners: 200,
    hobbyists: 350,
  },
  experience: {
    oneToFive: 250,
    sixToTen: 200,
    elevenToTwenty: 450,
    overTwenty: 450,
  },
  origin: { dominica: 400, carribean: 100, international: 350 },
};

function About() {
  const carpenterByParish = useSelector(
    (state) => state.registry.carpenterByParish
  );
  return (
    <div className="flex flex-col font-sans md:overflow-x-hidden">
      <BannerHero />
      <VideoEmbed />
      <StatisticsMap
        saintJohn={carpenterByParish.SAINT_JOHN}
        saintAndrew={carpenterByParish.SAINT_ANDREW}
        saintPeter={carpenterByParish.SAINT_PETER}
        saintDavid={carpenterByParish.SAINT_DAVID}
        saintJoseph={carpenterByParish.SAINT_JOSEPH}
        saintGeorge={carpenterByParish.SAINT_GEORGE}
        saintPaul={carpenterByParish.SAINT_PAUL}
        saintPatrick={carpenterByParish.SAINT_PATRICK}
        saintLuke={carpenterByParish.SAINT_LUKE}
        saintMark={carpenterByParish.SAINT_MARK}
      />
      <StatisticsDiagrams
        expertiseData={DIAGRAMDATA.expertise}
        experienceData={DIAGRAMDATA.experience}
        originData={DIAGRAMDATA.origin}
      />
      <div className="mx-auto block  w-full md:hidden lg:block">
        <BannerDetailedReport />
      </div>

      <ExpCarpenters />
    </div>
  );
}

export default About;
