const NameChip = (props) => {
  const { userName = "default username", onClick, size } = props;
  let initial = userName.charAt(0).toUpperCase();
  return size === "large" ? (
    <div
      className={`relative flex min-h-[65px] min-w-[65px] cursor-pointer items-center justify-center rounded-[32.5px] bg-primary-60 hover:bg-primary-80`}
      onClick={onClick}
    >
      <span className="absolute top-[calc(50%-21px)] left-[calc(50%-20px)] h-[40px] w-[40px] text-center align-middle text-4xl  font-semibold text-white">
        {initial}
      </span>
    </div>
  ) : (
    <div
      className={`relative flex min-h-[48px] min-w-[48px] cursor-pointer items-center justify-center rounded-[24px] bg-primary-60 hover:bg-primary-80`}
      onClick={onClick}
    >
      <span
        className="text-center align-middle text-[20px] font-semibold text-white md:text-[24px] lg:text-[32px] "
        onClick={onClick}
      >
        {initial}
      </span>
    </div>
  );
};

export default NameChip;
