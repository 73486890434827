import { useNavigate } from "react-router-dom";
const PARISHES = [
  { id: "SAINT_ANDREW", name: "Saint Andrew" },
  { id: "SAINT_DAVID", name: "Saint David" },
  { id: "SAINT_GEORGE", name: "Saint George" },
  { id: "SAINT_JOHN", name: "Saint John" },
  { id: "SAINT_JOSEPH", name: "Saint Joseph" },
  { id: "SAINT_LUKE", name: "Saint Luke" },
  { id: "SAINT_MARK", name: "Saint Mark" },
  { id: "SAINT_PATRICK", name: "Saint Patrick" },
  { id: "SAINT_PAUL", name: "Saint Paul" },
  { id: "SAINT_PETER", name: "Saint Peter" },
];
function CarpenterCard({ carpenter, className }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/registry/${carpenter.id}`);
  };
  const currentParish = PARISHES.find(
    (parish) => parish.id === carpenter.parish
  );
  return (
    <div
      className={`${className} flex h-[212px] 
      min-h-max w-[210px] content-center justify-center rounded-lg border-2 bg-white p-4 text-lg  capitalize hover:cursor-pointer  hover:border-green-200 hover:drop-shadow-[0_2px_36px_rgba(129,200,149,.1)] focus:border-green-200 xs:w-[260px] 
      md:w-[14.188rem] lg:w-[16.438rem]`}
      onClick={handleClick}
    >
      <div className="flex items-center">
        <div className="text-center">
          <p className="mb-[12px] text-3xl font-semibold">
            {carpenter.firstName}
          </p>
          <p className="mb-[12px]">{carpenter.businessName}</p>
          <p>{currentParish ? currentParish.name : ""}</p>
        </div>
      </div>
    </div>
  );
}

export default CarpenterCard;
