function BannerHero() {
  return (
    <div className="m-0 mb-10 flex max-w-[1440px] flex-nowrap items-center justify-between gap-12 py-0 md:mb-36 md:mt-12 md:pr-[2rem]  lg:pr-[6rem] xl:pr-[8rem]">
      <svg
        className="hidden h-auto w-full lg:block"
        viewBox="0 0 1451 538"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_2024_44490)">
          <path
            d="M487 77C487.717 77 488.305 76.4724 488.405 75.7258C489.391 69.076 490.298 68.1502 496.695 67.4235C497.432 67.3339 498 66.7167 498 66C498 65.2733 497.442 64.676 496.705 64.5665C490.348 63.6805 489.561 62.9041 488.405 56.2643C488.275 55.5276 487.707 55 487 55C486.273 55 485.695 55.5276 485.575 56.2742C484.609 62.914 483.702 63.8398 477.315 64.5665C476.558 64.6661 476 65.2633 476 66C476 66.7167 476.538 67.314 477.295 67.4235C483.662 68.3294 484.439 69.0959 485.575 75.7357C485.725 76.4824 486.303 77 487 77Z"
            fill="#65A880"
          />
        </g>
        <text
          x="130"
          y="150"
          fill="black"
          className=" text-[62px] font-semibold "
        >
          Registry of
        </text>
        <text
          x="130"
          y="255"
          fill="#15803D"
          className=" text-[78px] font-semibold"
        >
          Carpenters
        </text>
        <text
          x="130"
          y="330"
          fill="#494949"
          className=" text-[36px] font-semibold"
        >
          in the Commonwealth of Dominica
        </text>
        <text x="130" y="385" fill="#787878" className=" text-[24px]">
          <tspan x="130">Database to help you find carpenters</tspan>
          <tspan x="130" dy="1.5em">
            in Dominica
          </tspan>
        </text>
        <path
          d="M1268 257.474C1233.48 257.474 1205 229.491 1205 194.974V194.974C1205 160.456 1232.98 132.474 1267.5 132.474L1331 132.474L1331 194.974C1331 229.491 1302.52 257.474 1268 257.474V257.474Z"
          fill="#16A34A"
        />
        <path
          d="M1288.75 215.474C1265.83 215.474 1247 196.893 1247 173.974V173.974C1247 151.054 1265.58 132.474 1288.5 132.474L1331 132.474L1331 173.474C1331 196.67 1311.94 215.474 1288.75 215.474V215.474Z"
          fill="#15803D"
        />
        <path
          d="M1299.5 195C1282.1 195 1268 180.897 1268 163.5V163.5C1268 146.103 1282.1 132 1299.5 132L1331 132L1331 163.5C1331 180.897 1316.9 195 1299.5 195V195Z"
          fill="#16A34A"
        />
        <path
          d="M876 71.7489C876 38.888 902.639 12 935.5 12V12C968.361 12 995 38.6391 995 71.5L995 132L936 132C902.863 132 876 104.886 876 71.7489V71.7489Z"
          fill="#16A34A"
        />
        <path
          d="M987.5 91.508L987.5 124.5L955.984 124.5C938.095 124.5 923.484 109.762 923.484 91.7525C923.484 74.0146 937.874 59.5 955.492 59.5C973.169 59.5 987.5 73.8305 987.5 91.508Z"
          stroke="white"
          strokeWidth="15"
        />
        <path
          d="M1440 7L1440 132L1331 132L1331 55.5C1331 28.7142 1352.71 7 1379.5 7L1440 7Z"
          fill="#15803D"
        />
        <path
          d="M941 137L941 228L876 228L876 185.5C876 158.714 897.714 137 924.5 137L941 137Z"
          fill="#16A34A"
        />
        <path
          d="M983 137L983 228L896 228L896 185.5C896 158.714 917.714 137 944.5 137L983 137Z"
          fill="#FFD66B"
        />
        <path
          d="M1338.5 305.5L1338.5 223.5L1432.5 223.5L1432.5 253C1432.5 281.995 1408.99 305.5 1380 305.5L1338.5 305.5Z"
          stroke="#15803D"
          strokeWidth="15"
        />
        <path
          d="M1338.5 320.5L1338.5 409.5L1432.5 409.5L1432.5 373C1432.5 344.005 1408.99 320.5 1380 320.5L1338.5 320.5Z"
          stroke="#FFC225"
          strokeWidth="15"
        />
        <path
          d="M1074 510H979V422H1019C1049.38 422 1074 446.624 1074 477V510Z"
          stroke="#15803D"
          strokeWidth="10"
        />
        <path
          d="M928 424C953.405 424 974 444.595 974 470L974 473C974 496.196 955.196 515 932 515L882 515L882 470C882 444.595 902.595 424 928 424V424Z"
          fill="#16A34A"
        />
        <path
          d="M887 419L887 344L972 344L972 364C972 394.376 947.376 419 917 419L887 419Z"
          stroke="#15803D"
          strokeWidth="10"
        />
        <path
          d="M945.339 381.17C945.339 391.204 937.204 399.339 927.169 399.339V399.339C917.135 399.339 909 391.204 909 381.17L909 363L927.169 363C937.204 363 945.339 371.135 945.339 381.17V381.17Z"
          fill="#15803D"
        />
        <path
          d="M1000 48.5L1000 13L1037 13C1056.88 13 1073 29.1178 1073 49C1073 68.843 1056.67 85 1036.75 85C1016.55 85 1000 68.6192 1000 48.5Z"
          stroke="#FFC225"
          strokeWidth="10"
        />
        <path
          d="M1205 128L1143.5 128C1108.43 128 1080 99.5701 1080 64.5L1080 8L1141.5 8C1176.57 8.00001 1205 36.4299 1205 71.5L1205 128Z"
          fill="#15803D"
        />
        <path
          d="M1205 128.474L1205 191.974C1205 227.044 1176.57 255.474 1141.5 255.474L1077 255.474L1077 191.974C1077 156.904 1105.43 128.474 1140.5 128.474L1205 128.474Z"
          fill="#16A34A"
        />
        <path
          d="M983 344.723V344.723C1026.38 336.353 1068.9 364.629 1077.27 408.012L1079 417V417C1035.62 425.37 993.103 397.094 984.734 353.711L983 344.723Z"
          fill="#FFD66B"
        />
        <path
          d="M1170 293V293C1170 266.49 1191.49 245 1218 245L1224 245V245C1224 271.51 1202.51 293 1176 293L1170 293Z"
          fill="#FFD66B"
        />
        <ellipse
          cx="1268"
          cy="70"
          rx="63"
          ry="63"
          transform="rotate(90 1268 70)"
          fill="#FFC225"
        />
        <ellipse
          cx="1268"
          cy="70"
          rx="45"
          ry="45"
          transform="rotate(90 1268 70)"
          fill="#FFD66B"
        />
        <ellipse
          cx="1268"
          cy="70"
          rx="28"
          ry="28"
          transform="rotate(90 1268 70)"
          fill="#FFE9B1"
        />
        <ellipse
          cx="1128.5"
          cy="345"
          rx="64"
          ry="63.5"
          transform="rotate(90 1128.5 345)"
          fill="#15803D"
        />
        <ellipse
          cx="1128.5"
          cy="345"
          rx="47"
          ry="46.5"
          transform="rotate(90 1128.5 345)"
          fill="#16A34A"
        />
        <ellipse
          cx="1128.5"
          cy="345"
          rx="28"
          ry="27.5"
          transform="rotate(90 1128.5 345)"
          fill="#81C895"
        />
        <path
          d="M1165.5 442C1155.28 442 1147 433.493 1147 423C1147 412.507 1155.28 404 1165.5 404C1175.72 404 1184 412.507 1184 423C1184 433.493 1175.72 442 1165.5 442Z"
          fill="#15803D"
        />
        <path
          d="M1205 128.474L1205 191.974C1205 227.044 1176.57 255.474 1141.5 255.474L1077 255.474L1077 191.974C1077 156.904 1105.43 128.474 1140.5 128.474L1205 128.474Z"
          fill="#16A34A"
        />
        <ellipse
          cx="1096.5"
          cy="230.974"
          rx="14.5"
          ry="14.5"
          transform="rotate(90 1096.5 230.974)"
          fill="#15803D"
        />
        <ellipse
          cx="1096.5"
          cy="272.974"
          rx="14.5"
          ry="14.5"
          transform="rotate(90 1096.5 272.974)"
          fill="#FFD66B"
        />
        <ellipse
          cx="1008.5"
          cy="188.5"
          rx="14.5"
          ry="14.5"
          transform="rotate(90 1008.5 188.5)"
          fill="#15803D"
        />
        <ellipse
          cx="1008.5"
          cy="230.5"
          rx="14.5"
          ry="14.5"
          transform="rotate(90 1008.5 230.5)"
          fill="#FFD66B"
        />
        <ellipse
          cx="1052"
          cy="230.974"
          rx="14.5"
          ry="15"
          transform="rotate(90 1052 230.974)"
          fill="#EABF50"
        />
        <path
          d="M1077 254.474L1077 257.474C1077 301.656 1041.18 337.474 997 337.474L983 337.474L983 334.474C983 290.291 1018.82 254.474 1063 254.474L1077 254.474Z"
          fill="#FFD66B"
        />
        <ellipse
          cx="1057"
          cy="272.5"
          rx="14.5"
          ry="15"
          transform="rotate(90 1057 272.5)"
          fill="#FFC225"
        />
        <rect
          x="1278"
          y="515"
          width="18"
          height="98"
          rx="9"
          transform="rotate(-180 1278 515)"
          fill="#15803D"
        />
        <rect
          x="1302"
          y="515"
          width="18"
          height="98"
          rx="9"
          transform="rotate(-180 1302 515)"
          fill="#16A34A"
        />
        <rect
          x="1327"
          y="515"
          width="18"
          height="98"
          rx="9"
          transform="rotate(-180 1327 515)"
          fill="#81C895"
        />
        <rect
          x="1253"
          y="515"
          width="17"
          height="98"
          rx="8.5"
          transform="rotate(-180 1253 515)"
          fill="#16A34A"
        />
        <rect
          x="1230"
          y="515"
          width="18"
          height="98"
          rx="9"
          transform="rotate(-180 1230 515)"
          fill="#81C895"
        />
        <rect
          x="1207"
          y="515"
          width="18"
          height="98"
          rx="9"
          transform="rotate(-180 1207 515)"
          fill="#81C895"
        />
        <ellipse
          cx="1045.5"
          cy="133.5"
          rx="44.5"
          ry="44.5"
          transform="rotate(90 1045.5 133.5)"
          fill="#FFD66B"
        />
        <circle
          cx="1045.5"
          cy="133.499"
          r="32.5"
          transform="rotate(90 1045.5 133.499)"
          fill="#FFC225"
        />
        <circle
          cx="1045.5"
          cy="133.499"
          r="18.5"
          transform="rotate(90 1045.5 133.499)"
          fill="#ECB222"
        />
        <path
          d="M1258.37 260.823C1260.34 258.745 1263.66 258.745 1265.63 260.823L1325.87 324.309C1328.89 327.494 1326.63 332.75 1322.24 332.75H1201.76C1197.37 332.75 1195.11 327.494 1198.13 324.309L1258.37 260.823Z"
          fill="#FFDF8E"
        />
        <rect
          x="1196.44"
          y="325.022"
          width="130.611"
          height="91.9781"
          rx="3"
          fill="#FFDF8E"
        />
        <path
          d="M1296 417L1228 417L1228 345L1247.5 345C1274.29 345 1296 366.714 1296 393.5L1296 417Z"
          fill="#FFC225"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1440 132.474H1394.23V160.628H1440V132.474ZM1365.93 160.628H1440V188.783L1440 216.938H1331V188.783H1365.93V160.628Z"
          fill="#FFD66B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1079 417H1136.94V449.509H1079V417ZM1172.78 449.509H1079V482.018V482.018V514.526H1217V482.018H1172.78V449.509Z"
          fill="#FFC225"
        />
        <circle
          cx="941.199"
          cy="295.181"
          r="41.7596"
          transform="rotate(39.8568 941.199 295.181)"
          fill="#FFDF8E"
        />
        <circle
          cx="898.87"
          cy="251.87"
          r="23.3363"
          transform="rotate(39.8568 898.87 251.87)"
          fill="#FFC225"
        />
        <circle
          cx="898.87"
          cy="251.87"
          r="18.3363"
          transform="rotate(39.8568 898.87 251.87)"
          stroke="white"
          strokeOpacity="0.2"
          strokeWidth="10"
        />
        <circle
          cx="941.247"
          cy="296.156"
          r="17.8093"
          transform="rotate(39.8568 941.247 296.156)"
          fill="#FFC225"
        />
        <circle
          cx="1374.68"
          cy="470.684"
          r="47.2599"
          transform="rotate(138.86 1374.68 470.684)"
          fill="#15803D"
        />
        <circle
          cx="1375.09"
          cy="471.435"
          r="38.3321"
          transform="rotate(138.86 1375.09 471.435)"
          fill="#16A34A"
        />
        <circle
          cx="1374.8"
          cy="470.457"
          r="28.4458"
          transform="rotate(138.86 1374.8 470.457)"
          fill="#16A34A"
        />
        <circle
          cx="1374.64"
          cy="470.886"
          r="15.9246"
          transform="rotate(138.86 1374.64 470.886)"
          fill="#81C895"
        />
        <circle
          cx="1419.86"
          cy="439.225"
          r="21.4209"
          transform="rotate(138.86 1419.86 439.225)"
          fill="#FFC225"
        />
        <circle
          cx="1419.86"
          cy="439.225"
          r="16.4209"
          transform="rotate(138.86 1419.86 439.225)"
          stroke="white"
          strokeOpacity="0.2"
          strokeWidth="10"
        />
        <defs>
          <filter
            id="filter0_d_2024_44490"
            x="472"
            y="55"
            width="30"
            height="30"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2024_44490"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2024_44490"
              result="shape"
            />
          </filter>
        </defs>
      </svg>

      <svg
        className="hidden h-auto w-full md:block lg:hidden"
        viewBox="0 0 845 396"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <text x="30" y="90" fill="black" className=" text-[40px] font-semibold">
          Registry of
        </text>
        <text
          x="30"
          y="145"
          fill="#15803D"
          className=" text-[40px] font-semibold"
        >
          Carpenters
        </text>
        <text
          x="30"
          y="190"
          fill="#494949"
          className=" text-[24px] font-semibold"
        >
          in the Commonwealth of Dominica
        </text>
        <text x="30" y="230" fill="#787878" className=" text-[20px]">
          <tspan x="30">Database to help you find carpenters</tspan>
          <tspan x="30" dy="1.5em">
            in Dominica
          </tspan>
        </text>
        <path
          d="M784.907 195C757.948 195 736 173.145 736 146.185V146.185C736 119.225 757.855 97.3699 784.815 97.3699L834 97.3699L834 146C834 173.062 811.969 195 784.907 195V195Z"
          fill="#16A34A"
        />
        <path
          d="M801.206 162.196C783.305 162.196 768.667 147.684 768.667 129.783V129.783C768.667 111.882 783.179 97.3699 801.08 97.3699L834 97.3699L834 129.53C834 147.571 819.247 162.196 801.206 162.196V162.196Z"
          fill="#15803D"
        />
        <path
          d="M809.5 146.206C795.969 146.206 785 135.185 785 121.654V121.654C785 108.067 796.015 97 809.603 97L834 97L834 121.706C834 135.237 823.031 146.206 809.5 146.206V146.206Z"
          fill="#16A34A"
        />
        <path
          d="M480 50.2486C480 24.8435 500.595 4 526 4V4C551.405 4 572 24.5949 572 50L572 97L526.5 97C500.819 97 480 75.9299 480 50.2486V50.2486Z"
          fill="#16A34A"
        />
        <path
          d="M567 65.544L567 92L541.912 92C527.6 92 515.912 80.1778 515.912 65.7703C515.912 51.6146 527.396 40 541.456 40C555.564 40 567 51.4364 567 65.544Z"
          stroke="white"
          strokeWidth="10"
        />
        <path
          d="M530.421 101L530.421 172L480 172L480 149.5C480 122.714 501.714 101 528.5 101L530.421 101Z"
          fill="#16A34A"
        />
        <path
          d="M563 101L563 172L495.514 172L495.514 149.5C495.514 122.714 517.228 101 544.014 101L563 101Z"
          fill="#FFD66B"
        />
        <path
          d="M633 391H561V324H578C608.376 324 633 348.624 633 379V391Z"
          stroke="#15803D"
          strokeWidth="10"
        />
        <path
          d="M520 325C539.882 325 556 341.118 556 361L556 363.05C556 381.248 541.248 396 523.05 396L484 396L484 361C484 341.118 500.118 325 520 325V325Z"
          fill="#16A34A"
        />
        <path
          d="M489 320L489 264L553 264L553 265C553 295.376 528.376 320 498 320L489 320Z"
          stroke="#15803D"
          strokeWidth="10"
        />
        <path
          d="M533.338 291.744C533.338 299.535 526.999 305.852 519.207 305.852V305.852C511.391 305.852 505.032 299.515 505.032 291.699L505.032 277.636L519.23 277.636C527.021 277.636 533.338 283.952 533.338 291.744V291.744Z"
          fill="#15803D"
        />
        <path
          d="M574.5 32.5L574.5 3.50001L605 3.50001C621.292 3.50001 634.5 16.7076 634.5 33C634.5 49.2674 621.07 62.5 604.752 62.5C588.158 62.5 574.5 49.0435 574.5 32.5Z"
          stroke="#FFC225"
          strokeWidth="5"
        />
        <path
          d="M736 94L702.5 94C667.43 94 639 65.5701 639 30.5L639 0.999999L672.5 1C707.57 1.00001 736 29.4299 736 64.5L736 94Z"
          fill="#15803D"
        />
        <path
          d="M563 262.877V262.877C597.135 256.317 630.164 278.664 636.773 312.789L638 319.123V319.123C603.865 325.682 570.836 303.336 564.227 269.211L563 262.877Z"
          fill="#FFD66B"
        />
        <path
          d="M709 223V223C709 202.013 726.013 185 747 185L751 185V185C751 205.987 733.987 223 713 223L709 223Z"
          fill="#FFD66B"
        />
        <circle
          cx="785"
          cy="49"
          r="49"
          transform="rotate(90 785 49)"
          fill="#FFC225"
        />
        <circle
          cx="785"
          cy="49"
          r="35"
          transform="rotate(90 785 49)"
          fill="#FFD66B"
        />
        <circle
          cx="785"
          cy="48.9999"
          r="21.7778"
          transform="rotate(90 785 48.9999)"
          fill="#FFE9B1"
        />
        <ellipse
          cx="676.5"
          cy="263"
          rx="50"
          ry="49.5"
          transform="rotate(90 676.5 263)"
          fill="#15803D"
        />
        <ellipse
          cx="676.5"
          cy="263"
          rx="36.7188"
          ry="36.248"
          transform="rotate(90 676.5 263)"
          fill="#16A34A"
        />
        <ellipse
          cx="676.5"
          cy="263"
          rx="21.875"
          ry="21.437"
          transform="rotate(90 676.5 263)"
          fill="#81C895"
        />
        <path
          d="M705.5 339C697.492 339 691 332.284 691 324C691 315.716 697.492 309 705.5 309C713.508 309 720 315.716 720 324C720 332.284 713.508 339 705.5 339Z"
          fill="#15803D"
        />
        <path
          d="M736 95L736 130.5C736 165.57 707.57 194 672.5 194L636 194L636 158.5C636 123.43 664.43 95 699.5 95L736 95Z"
          fill="#16A34A"
        />
        <ellipse
          cx="651.234"
          cy="174.902"
          rx="11.3031"
          ry="11.3281"
          transform="rotate(90 651.234 174.902)"
          fill="#15803D"
        />
        <ellipse
          cx="651.5"
          cy="207"
          rx="11"
          ry="11.5"
          transform="rotate(90 651.5 207)"
          fill="#FFD66B"
        />
        <ellipse
          cx="583"
          cy="141.5"
          rx="11.5"
          ry="11"
          transform="rotate(90 583 141.5)"
          fill="#15803D"
        />
        <circle
          cx="583"
          cy="174"
          r="11"
          transform="rotate(90 583 174)"
          fill="#FFD66B"
        />
        <ellipse
          cx="617"
          cy="174.5"
          rx="11.5"
          ry="12"
          transform="rotate(90 617 174.5)"
          fill="#EABF50"
        />
        <path
          d="M636 193V193C636 228.346 607.346 257 572 257L563 257V257C563 221.654 591.654 193 627 193L636 193Z"
          fill="#FFD66B"
        />
        <ellipse
          cx="620.468"
          cy="206.9"
          rx="11.1807"
          ry="11.6489"
          transform="rotate(90 620.468 206.9)"
          fill="#FFC225"
        />
        <rect
          x="793.007"
          y="396"
          width="13.9565"
          height="77"
          rx="6.97826"
          transform="rotate(-180 793.007 396)"
          fill="#15803D"
        />
        <rect
          x="811.616"
          y="396"
          width="13.9565"
          height="77"
          rx="6.97826"
          transform="rotate(-180 811.616 396)"
          fill="#16A34A"
        />
        <rect
          x="831"
          y="396"
          width="13.9565"
          height="77"
          rx="6.97826"
          transform="rotate(-180 831 396)"
          fill="#81C895"
        />
        <rect
          x="773.623"
          y="396"
          width="13.1812"
          height="77"
          rx="6.59058"
          transform="rotate(-180 773.623 396)"
          fill="#16A34A"
        />
        <rect
          x="755.79"
          y="396"
          width="13.9565"
          height="77"
          rx="6.97826"
          transform="rotate(-180 755.79 396)"
          fill="#81C895"
        />
        <rect
          x="737.957"
          y="396"
          width="13.9565"
          height="77"
          rx="6.97826"
          transform="rotate(-180 737.957 396)"
          fill="#81C895"
        />
        <circle
          cx="611.5"
          cy="98.5"
          r="34.5"
          transform="rotate(90 611.5 98.5)"
          fill="#FFD66B"
        />
        <ellipse
          cx="611.5"
          cy="98.4994"
          rx="25.1966"
          ry="25.1966"
          transform="rotate(90 611.5 98.4994)"
          fill="#FFC225"
        />
        <ellipse
          cx="611.5"
          cy="98.4994"
          rx="14.3427"
          ry="14.3427"
          transform="rotate(90 611.5 98.4994)"
          fill="#ECB222"
        />
        <path
          d="M776.878 198.807C778.848 196.736 782.152 196.736 784.122 198.807L828.322 245.26C831.351 248.444 829.094 253.706 824.699 253.706H736.301C731.906 253.706 729.649 248.444 732.678 245.26L776.878 198.807Z"
          fill="#FFDF8E"
        />
        <rect
          x="729.556"
          y="247.717"
          width="101.499"
          height="71.2832"
          rx="3"
          fill="#FFDF8E"
        />
        <path
          d="M806.922 319L754.078 319L754.078 263.2L758.422 263.2C785.207 263.2 806.922 284.914 806.922 311.7L806.922 319Z"
          fill="#FFC225"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M638 319H682.928V344.667H638V319ZM710.713 344.667H638V370.333V370.334V396H745V370.333H710.713V344.667Z"
          fill="#FFC225"
        />
        <ellipse
          cx="32.4364"
          cy="32.4495"
          rx="32.4364"
          ry="32.4495"
          transform="matrix(0.766932 0.641729 -0.640014 0.768363 526.247 178.503)"
          fill="#FFDF8E"
        />
        <ellipse
          cx="18.1262"
          cy="18.1336"
          rx="18.1262"
          ry="18.1336"
          transform="matrix(0.766932 0.641729 -0.640014 0.768363 495.211 165)"
          fill="#FFC225"
        />
        <path
          d="M507.574 198.989C502.931 204.563 494.66 205.308 489.102 200.657C483.543 196.006 482.797 187.717 487.44 182.142C492.084 176.567 500.355 175.823 505.913 180.474C511.471 185.125 512.218 193.414 507.574 198.989Z"
          stroke="white"
          strokeOpacity="0.2"
          strokeWidth="10"
        />
        <ellipse
          cx="13.8332"
          cy="13.8388"
          rx="13.8332"
          ry="13.8388"
          transform="matrix(0.766932 0.641729 -0.640014 0.768363 528.64 205.5)"
          fill="#FFC225"
        />
        <path
          d="M250 76C250.717 76 251.305 75.4724 251.405 74.7258C252.391 68.076 253.298 67.1502 259.695 66.4235C260.432 66.3339 261 65.7167 261 65C261 64.2733 260.442 63.676 259.705 63.5665C253.348 62.6805 252.561 61.9041 251.405 55.2643C251.275 54.5276 250.707 54 250 54C249.273 54 248.695 54.5276 248.575 55.2742C247.609 61.914 246.702 62.8398 240.315 63.5665C239.558 63.6661 239 64.2633 239 65C239 65.7167 239.538 66.314 240.295 66.4235C246.662 67.3294 247.439 68.0959 248.575 74.7357C248.725 75.4824 249.303 76 250 76Z"
          fill="#65A880"
          className="translate-y-[-25px]"
        />
      </svg>

      <svg
        className="block md:hidden"
        viewBox="0 0 375 467"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <text
          x="20"
          y="170"
          fill="black"
          className=" text-[36px] font-semibold"
        >
          Registry of
        </text>
        <text
          x="20"
          y="220"
          fill="#15803D"
          className=" text-[40px] font-semibold"
        >
          Carpenters
        </text>
        <text
          x="20"
          y="260"
          fill="#494949"
          className=" text-[20px] font-semibold"
        >
          <tspan x="20">in the Commonwealth of </tspan>
          <tspan x="20" dy="1.5em">
            Dominica
          </tspan>
        </text>
        <text x="20" y="320" fill="#787878" className=" text-[14px]">
          <tspan x="20">Database to help you find carpenters</tspan>
          <tspan x="20" dy="1.7em">
            in Dominica
          </tspan>
        </text>
        <circle
          cx="25"
          cy="415"
          r="8"
          transform="rotate(90 25 415)"
          fill="#EABF50"
        />
        <circle
          cx="8"
          cy="415"
          r="8"
          transform="rotate(90 8 415)"
          fill="#FFC225"
        />
        <path
          d="M21.5 423C33.3741 423 43 432.626 43 444.5L43 446.58C43 457.858 33.8577 467 22.5801 467L1.8914e-06 467L2.8749e-06 444.5C3.39394e-06 432.626 9.62588 423 21.5 423V423Z"
          fill="#16A34A"
        />
        <path
          d="M43 443.5C43 431.626 52.6259 422 64.5 422V422C76.3741 422 86 431.626 86 443.5L86 467L65.5 467C53.0736 467 43 456.926 43 444.5L43 443.5Z"
          fill="#16A34A"
        />
        <ellipse
          cx="108"
          cy="445.5"
          rx="21.5"
          ry="22"
          transform="rotate(90 108 445.5)"
          fill="#FFC225"
        />
        <ellipse
          cx="108.5"
          cy="445.5"
          rx="15.5"
          ry="15.5"
          transform="rotate(90 108.5 445.5)"
          fill="#FFD66B"
        />
        <ellipse
          cx="108"
          cy="445.5"
          rx="9.5"
          ry="10"
          transform="rotate(90 108 445.5)"
          fill="#FFE9B1"
        />
        <ellipse
          cx="59"
          cy="415"
          rx="8"
          ry="8"
          transform="rotate(90 59 415)"
          fill="#FFD66B"
        />
        <ellipse
          cx="8"
          cy="8"
          rx="8"
          ry="8"
          transform="matrix(4.37114e-08 1 1 -4.37114e-08 34 407)"
          fill="#15803D"
        />
        <path
          d="M83 452L83 464L72.5 464C66.1487 464 61 458.851 61 452.5L61 452C61 445.925 65.9249 441 72 441C78.0751 441 83 445.925 83 452Z"
          stroke="white"
          strokeWidth="6"
        />
        <path
          d="M203 23C215.15 23 225 32.8497 225 45V45C225 57.1503 215.15 67 203 67L181 67L181 45C181 32.8497 190.85 23 203 23V23Z"
          fill="#16A34A"
        />
        <path
          d="M315.956 90.2889C315.98 102.414 306.17 112.264 294.045 112.288V112.288C281.919 112.312 272.07 102.502 272.046 90.376L272 67.3996L293.445 67.357C305.853 67.3324 315.931 77.3707 315.955 89.7783L315.956 90.2889Z"
          fill="#FFC225"
        />
        <path
          d="M275.03 82.7094L275.006 70.3918L285.792 70.3704C292.313 70.3574 297.609 75.6331 297.622 82.154L297.623 82.6646C297.636 88.9034 292.588 93.9711 286.349 93.9835C280.11 93.9958 275.043 88.9483 275.03 82.7094Z"
          stroke="white"
          strokeWidth="6"
        />
        <ellipse
          cx="248.5"
          cy="43.5"
          rx="23.5"
          ry="23.5"
          transform="rotate(90 248.5 43.5)"
          fill="#FFC225"
        />
        <circle
          cx="248.5"
          cy="43.5"
          r="16.5"
          transform="rotate(90 248.5 43.5)"
          fill="#FFD66B"
        />
        <circle
          cx="248.5"
          cy="43.5"
          r="10.5"
          transform="rotate(90 248.5 43.5)"
          fill="#FFE9B1"
        />
        <circle
          cx="294"
          cy="120"
          r="8"
          transform="rotate(90 294 120)"
          fill="#FFD66B"
        />
        <circle
          cx="8"
          cy="8"
          r="8"
          transform="matrix(4.37114e-08 1 1 -4.37114e-08 270 112)"
          fill="#15803D"
        />
        <circle
          cx="8"
          cy="8"
          r="8"
          transform="matrix(4.37114e-08 1 1 -4.37114e-08 254 112)"
          fill="#FFC225"
        />
        <circle
          cx="8"
          cy="8"
          r="8"
          transform="matrix(4.37114e-08 1 1 -4.37114e-08 302 112)"
          fill="#EABF50"
        />
        <path
          d="M338 112.316C325.85 112.316 316 102.467 316 90.3164V90.3164C316 78.1661 325.85 68.3164 338 68.3164L360 68.3164L360 90.3164C360 102.467 350.15 112.316 338 112.316V112.316Z"
          fill="#16A34A"
        />
        <path
          d="M345.5 97.3164C337.492 97.3164 331 90.8245 331 82.8164V82.8164C331 74.8083 337.492 68.3164 345.5 68.3164L360 68.3164L360 82.8164C360 90.8245 353.508 97.3164 345.5 97.3164V97.3164Z"
          fill="#15803D"
        />
        <path
          d="M348.5 91.3164C342.149 91.3164 337 86.1677 337 79.8164V79.8164C337 73.4651 342.149 68.3164 348.5 68.3164L360 68.3164L360 79.8164C360 86.1677 354.851 91.3164 348.5 91.3164V91.3164Z"
          fill="#16A34A"
        />
        <path
          d="M228 89C228 76.8497 237.85 67 250 67V67C262.15 67 272 76.8497 272 89L272 112L250.5 112C238.074 112 228 101.926 228 89.5L228 89Z"
          fill="#15803D"
        />
        <path
          d="M243 96.5C243 88.4919 249.492 82 257.5 82V82C265.508 82 272 88.4919 272 96.5L272 111L257.5 111C249.492 111 243 104.508 243 96.5V96.5Z"
          fill="#16A34A"
        />
        <path
          d="M249 99.5C249 93.1487 254.149 88 260.5 88V88C266.851 88 272 93.1487 272 99.5L272 111L260.5 111C254.149 111 249 105.851 249 99.5V99.5Z"
          fill="#15803D"
        />
        <path
          d="M316 46C316 58.1503 306.15 68 294 68V68C281.85 68 272 58.1503 272 46L272 24L294 24C306.15 24 316 33.8497 316 46V46Z"
          fill="#15803D"
        />
        <path
          d="M301 38.5C301 46.5081 294.508 53 286.5 53V53C278.492 53 272 46.5081 272 38.5L272 24L286.5 24C294.508 24 301 30.4919 301 38.5V38.5Z"
          fill="#16A34A"
        />
        <path
          d="M295 35.5C295 41.8513 289.851 47 283.5 47V47C277.149 47 272 41.8513 272 35.5L272 24L283.5 24C289.851 24 295 29.1487 295 35.5V35.5Z"
          fill="#15803D"
        />
        <circle
          cx="355.283"
          cy="17.2832"
          r="12.2488"
          transform="rotate(138.86 355.283 17.2832)"
          fill="#FFC225"
        />
        <circle
          cx="355.283"
          cy="17.2832"
          r="11.2488"
          transform="rotate(138.86 355.283 17.2832)"
          stroke="white"
          strokeOpacity="0.2"
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M339.535 16.8869C338.758 16.1263 337.515 16.1263 336.737 16.8869L317.341 35.8649C317.187 36.0154 317.065 36.1792 316.971 36.351C316.389 36.7007 316 37.3381 316 38.0666V65.6709C316 66.7755 316.895 67.6709 318 67.6709H358C359.105 67.6709 360 66.7755 360 65.6709V38.0666C360 37.5289 359.788 37.0408 359.443 36.6814C359.349 36.3895 359.181 36.1096 358.931 35.8649L339.535 16.8869Z"
          fill="#FFD66B"
        />
        <path
          d="M353 67.3164L330 67.3164L330 43.3164V43.3164C342.703 43.3164 353 53.6139 353 66.3164L353 67.3164Z"
          fill="#FFC225"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M374.823 159H354.743V172.897H374.823V159ZM342.324 172.897H374.823V186.795H374.823V200.692H327V186.795H342.324V172.897Z"
          fill="#FFC225"
        />
        <ellipse
          cx="338"
          cy="134"
          rx="22"
          ry="22"
          transform="rotate(90 338 134)"
          fill="#15803D"
        />
        <ellipse
          cx="338"
          cy="134"
          rx="16"
          ry="16"
          transform="rotate(90 338 134)"
          fill="#16A34A"
        />
        <circle
          cx="338"
          cy="134"
          r="9"
          transform="rotate(90 338 134)"
          fill="#81C895"
        />
        <path
          d="M162 136C162.522 136 162.949 135.616 163.022 135.073C163.739 130.237 164.399 129.564 169.051 129.035C169.587 128.97 170 128.521 170 128C170 127.471 169.594 127.037 169.058 126.957C164.435 126.313 163.862 125.748 163.022 120.919C162.928 120.384 162.514 120 162 120C161.471 120 161.051 120.384 160.964 120.927C160.261 125.756 159.601 126.429 154.957 126.957C154.406 127.03 154 127.464 154 128C154 128.521 154.391 128.956 154.942 129.035C159.572 129.694 160.138 130.252 160.964 135.081C161.072 135.624 161.493 136 162 136Z"
          fill="#65A880"
        />
      </svg>
    </div>
  );
}

export default BannerHero;
