import { createSlice } from "@reduxjs/toolkit";
import {
  getAProjectById,
  createProject,
  deleteProject,
  updateProject,
} from "./../utils/aws.services";
import { Storage } from "@aws-amplify/storage";
import { Auth } from "@aws-amplify/auth/lib-esm/Auth";
import { v4 } from "uuid";
import { registryActions } from "./registry";

const initialState = {
  projects: [],
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjects(state, action) {
      state.projects = action.payload;
    },
    addAProject(state, action) {
      state.projects = [action.payload, ...state.projects];
    },
    editAProject(state, action) {
      const index = state.projects.findIndex(
        (project) => project.id === action.payload.id
      );
      const currentProject = state.projects[index];
      state.projects[index] = { ...currentProject, ...action.payload };
    },
    deleteProject(state, action) {
      const projects = [...state.projects];
      const index = projects.findIndex(
        (project) => project.id === action.payload
      );
      projects.splice(index, 1);
      state.projects = projects;
    },
  },
});

export default projectSlice.reducer;
export const projectActions = projectSlice.actions;

export const fetchAProject = (id) => {
  return async (dispatch, getState) => {
    const projects = getState().project.projects;
    const currentProject = projects.find((project) => project.id === id);

    if (!currentProject) {
      let projectData;
      try {
        projectData = await getAProjectById(id);
      } catch (error) {
        return error;
      }

      let displayImages = [];
      let promises = [];
      try {
        projectData.images.forEach((rawImage) => {
          promises.push(Storage.get(rawImage, { level: "public" }));
        });
        displayImages = await Promise.all(promises);
      } catch (error) {
        console.log(error);
        // return error;
      }

      dispatch(
        projectActions.addAProject({
          ...projectData,
          displayImages: displayImages,
        })
      );
    }
  };
};

export const createAProject = ({ name, description, files }) => {
  return async (dispatch, getState) => {
    const carpenters = getState().registry.carpenters;
    let user;
    //Get the current authenticated user
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (error) {
      return error;
    }
    //Is not authenticated, throw an error
    if (!user) {
      throw new Error("Could not proceed, please login.");
    }

    //If there is no user data, throw an error
    const userData = carpenters.find(
      (carpenter) => carpenter.id === user.username
    );
    if (!userData) {
      throw new Error("Could not proceed, please try again.");
    }

    //Create a unique id
    const id = v4();
    let project;
    let images = [];
    let temporaryImages = [];
    files.forEach((file, index) => {
      //Create temporary image link in the disk
      temporaryImages.push(URL.createObjectURL(file));
      //Create unique image id
      const currentTime = new Date(Date.now());
      images.push(id + "-" + currentTime.toISOString() + `-${file.name}`);
    });
    try {
      const response = await createProject({
        id,
        name,
        description,
        images,
        ownerId: user.username,
      });
      project = response.data.createProjects;
      dispatch(
        projectActions.addAProject({
          id,
          name,
          description,
          displayImages: temporaryImages,
          ownerId: user.username,
        })
      );
    } catch (error) {
      throw error;
    }

    if (!project) {
      throw new Error("Could not proceed, please login.");
    }

    let promises = [];
    try {
      files.forEach((file, index) =>
        promises.push(
          Storage.vault.put(images[index], file, {
            resumable: true,
            level: "public",
            contentType: file.type,
          })
        )
      );
      await Promise.all(promises);
      dispatch(
        registryActions.updateACarpenter({
          ...userData,
          project: [...userData.project, id],
        })
      );
    } catch (error) {
      try {
        await deleteProject(id, project._version);
      } catch (error) {
        throw error;
      }
      throw error;
    }
  };
};

export const editAProject = (
  id,
  currentVersion,
  newData,
  remainImages,
  deletedImages,
  files,
  displayImages
) => {
  return async (dispatch, getState) => {
    if (deletedImages.length > 0) {
      let promises = [];
      try {
        deletedImages.forEach((image) => promises.push(Storage.remove(image)));
        await Promise.all(promises);
      } catch (error) {
        throw error;
      }
    }

    let images = [];
    // let temporaryImages = [];
    if (files.length > 0) {
      let promises = [];

      files.forEach((file, index) => {
        //Create temporary image link in the disk
        // temporaryImages.push(URL.createObjectURL(file));
        //Create unique image id
        const currentTime = new Date(Date.now());
        images.push(id + "-" + currentTime.toISOString() + `-${file.name}`);
      });
      try {
        files.forEach((file, index) =>
          promises.push(
            Storage.vault.put(images[index], file, {
              resumable: true,
              level: "public",
              contentType: file.type,
            })
          )
        );
        await Promise.all(promises);
      } catch (error) {
        throw error;
      }
    }

    try {
      await updateProject(id, currentVersion, {
        ...newData,
        images: [...remainImages, ...images],
      });
      dispatch(
        projectActions.editAProject({
          id,
          ...newData,
          displayImages: [...displayImages],
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteAProject = (id) => {
  return async (dispatch, getState) => {
    const projects = getState().project.projects;
    const currentProject = projects.find((project) => project.id === id);
    const carpenterId = currentProject.ProjectBelongToCarpenter.id;
    const carpenters = getState().registry.carpenters;
    const currentCarpenter = carpenters.find(
      (carpenter) => carpenter.id === carpenterId
    );

    if (!currentProject || !currentCarpenter) {
      throw new Error("Could not find this project, please try again.");
    }

    try {
      await deleteProject(id, currentProject._version);
    } catch (error) {
      throw error;
    }
    if (currentProject.images.length > 0) {
      let promises = [];
      try {
        currentProject.images.forEach((image) =>
          promises.push(Storage.remove(image))
        );
        await Promise.all(promises);
      } catch (error) {
        throw error;
      }
    }
    const carpenterProjects = currentCarpenter.project.filter(
      (project) => project !== id
    );
    dispatch(
      registryActions.updateACarpenter({
        ...currentCarpenter,
        project: carpenterProjects,
      })
    );
    dispatch(projectActions.deleteProject(id));
  };
};
