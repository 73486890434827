import YouTubeEmbed from "./YouTubeEmbed";

function VideoEmbed() {
  return (
    <div
      className="flex flex-col items-center justify-between gap-[48px] px-5  
    md:gap-[48px] md:px-12 lg:flex-row lg:gap-[6rem] "
    >
      <div className="relative flex w-full justify-center lg:w-1/2  lg:pl-16">
        <div
          className=" -z-5 absolute left-[-5rem] hidden  w-fit  items-end pl-12 md:-top-12  
        md:left-[calc(50%-380px)]  md:flex lg:-left-2 lg:pl-0"
        >
          <svg
            className="w-[100px]  lg:w-[120px] "
            fill="none"
            viewBox="0 0 120 145"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.5 2.5h46c25.405 0 46 20.595 46 46v47h-46c-25.405 0-46-20.595-46-46v-47z"
              stroke="#81C895"
              strokeWidth={5}
            />
            <path
              d="M2.5 49.5h46c25.405 0 46 20.595 46 46v47h-46c-25.405 0-46-20.595-46-46v-47z"
              stroke="#6AC18C"
              strokeOpacity={0.2}
              strokeWidth={5}
            />
          </svg>
        </div>
        <YouTubeEmbed />
      </div>
      <div className=" flex w-full flex-col  justify-between gap-[24px] py-2  md:w-3/4 md:gap-[36px] lg:w-1/2 lg:gap-[40px] ">
        <div className=" relative  flex  w-full flex-nowrap justify-center gap-2 lg:w-3/4 lg:justify-start">
          <h3
            className=" relative  text-[24px] font-semibold
           md:text-4xl md:font-semibold  
           lg:text-[2.75rem] lg:font-bold  "
          >
            Headline
            <svg
              className=" absolute right-[-20px] top-[-20px]  lg:hidden"
              width="24"
              height="26"
              viewBox="0 0 24 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 4.5L24 10.5C24 18.7843 17.2843 25.5 9 25.5L3 25.5L3 19.5C3 11.2157 9.71573 4.5 18 4.5L24 4.5Z"
                fill="#FFDF8E"
              />
              <path
                d="M21 0L21 6C21 14.2843 14.2843 21 6 21L-2.82529e-06 21L-2.56302e-06 15C-2.2009e-06 6.71573 6.71573 -6.24385e-07 15 -2.62268e-07L21 0Z"
                fill="#F9DA85"
                fillOpacity="0.3"
              />
            </svg>
            <svg
              className="absolute right-[-50px] top-[-50px] hidden lg:inline"
              width={52}
              height={53}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M52 8v29c0 8.837-7.163 16-16 16H6V28C6 16.954 14.954 8 26 8h26z"
                fill="#FFDF8E"
              />
              <path
                d="M46 0v29c0 8.837-7.163 16-16 16H0V20C0 8.954 8.954 0 20 0h26z"
                fill="#F9DA85"
                fillOpacity={0.3}
              />
            </svg>
          </h3>
        </div>
        <p className="leading-7.5  text-secondary-dark  text-justify text-[16px] md:text-[1.25rem] lg:w-5/6 ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          suscipit quis urna vitae lobortis. Vestibulum malesuada ligula dui.
          Maecenas a urna massa. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Donec suscipit quis urna vitae lobortis. Vestibulum
          malesuada ligula dui. Maecenas a urna massa.
        </p>
      </div>
    </div>
  );
}

export default VideoEmbed;
