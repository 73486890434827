import PieChart from "./PieChart";
import BannerDetailedReport from "./BannerDetailedReport";
const StatisticsDiagrams = ({ expertiseData, experienceData, originData }) => {
  const mappedData = [
    {
      chartName: "Expertise",
      labels: ["Carpenters", "Joiners", "Hobbyists"],
      data: [
        expertiseData.carpenters,
        expertiseData.joiners,
        expertiseData.hobbyists,
      ],
      backgroundColor: ["#81C895", "#16A34A", "#166534"],
      shadowColor: "#6bc08c",
      borderColor: "#D3ECDD",
    },
    {
      chartName: "Experience",
      labels: [
        "1 to 5 years",
        "6 to 10 years",
        "11 to 20 years",
        "over 20 years",
      ],
      data: [
        experienceData.oneToFive,
        experienceData.sixToTen,
        experienceData.elevenToTwenty,
        experienceData.overTwenty,
      ],
      backgroundColor: ["#FFF3D3", "#FFE9B1", "#FFDF8E", "#FFD66B"],
      shadowColor: "#f9da85",
      borderColor: "#FFF3D3",
    },
    {
      chartName: "Origin",
      labels: ["Dominica", "Carribean", "International"],
      data: [
        originData.dominica,
        originData.carribean,
        originData.international,
      ],
      backgroundColor: ["#FFBBBB", "#FF9D9D", "#FF7E7E"],
      shadowColor: "#e85e6a",
      borderColor: "#ffd9d9",
    },
  ];

  return (
    <div
      className="  mx-auto mb-[56px] grid w-fit grid-cols-1  justify-center gap-[24px] px-4  md:mx-0 
    md:mb-[134px] md:w-auto md:grid-cols-statistics-diagram md:gap-[30px] md:px-1 lg:mb-[168.5px]"
    >
      {mappedData.map((data, index) => (
        <PieChart
          key={index}
          chartName={data.chartName}
          labels={data.labels}
          data={data.data}
          backgroundColor={data.backgroundColor}
          shadowColor={data.shadowColor}
          borderColor={data.borderColor}
        />
      ))}
      <div className="hidden h-[323px] rounded-xl md:flex lg:hidden">
        <BannerDetailedReport />
      </div>
    </div>
  );
};
export default StatisticsDiagrams;
