/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "d18188e2c0ea4a55a18746fe5eb686bb",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "d18188e2c0ea4a55a18746fe5eb686bb",
            "region": "us-east-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://uieu7xme2fffxhwf5xovlbhq4q.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-divcr4uqtbgbfouzagz656ur4m",
    "aws_cognito_identity_pool_id": "us-east-1:27b971d5-1dd8-4efd-ba8a-3c8cc8a4904a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6UT15FgsL",
    "aws_user_pools_web_client_id": "3nmcigrnskfikjnbp78vhn52v3",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "sage-api-bucket50638-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
