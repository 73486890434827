import { createSlice } from "@reduxjs/toolkit";
import { getAllServices } from "../utils/aws.services";
 
const initialState = {
  services: [],
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setServices(state, action) {
      state.services = action.payload;
    },
  },
});

export default serviceSlice.reducer;
export const serviceActions = serviceSlice.actions;

export const fetchServices = () => {
  return async (dispatch, getState) => {
    try {
      const services = await getAllServices();
      dispatch(serviceActions.setServices(services));
    } catch (error) {
      console.log(error);
    }
  };
};
