/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getServices = /* GraphQL */ `
  query GetServices($id: ID!) {
    getServices(id: $id) {
      id
      name
      color
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        color
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncServices = /* GraphQL */ `
  query SyncServices(
    $filter: ModelServicesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        color
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProjects = /* GraphQL */ `
  query GetProjects($id: ID!) {
    getProjects(id: $id) {
      id
      name
      images
      description
      ownerId
      ProjectBelongToCarpenter {
        id
        phone
        firstName
        lastName
        businessName
        address
        email
        project
        services
        rebuild
        beAMentor
        beMentored
        isApproved
        parish
        userStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        images
        description
        ownerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProjects = /* GraphQL */ `
  query SyncProjects(
    $filter: ModelProjectsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        images
        description
        ownerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const projectsByOwnerId = /* GraphQL */ `
  query ProjectsByOwnerId(
    $ownerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByOwnerId(
      ownerId: $ownerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        images
        description
        ownerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCarpenters = /* GraphQL */ `
  query GetCarpenters($id: ID!) {
    getCarpenters(id: $id) {
      id
      phone
      firstName
      lastName
      businessName
      address
      email
      project
      services
      rebuild
      beAMentor
      beMentored
      isApproved
      parish
      firstTimeLogin
      CarpenterHasManyProjects(filter: { _deleted: { ne: true } }) {
        items {
          id
          name
          _deleted
        }
      }
      userStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCarpenters = /* GraphQL */ `
  query ListCarpenters($limit: Int, $nextToken: String) {
    listCarpenters(
      filter: { _deleted: { ne: true } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        phone
        firstName
        lastName
        businessName
        address
        email
        project
        services
        rebuild
        beAMentor
        beMentored
        isApproved
        parish
        userStatus
        createdAt
        updatedAt
        firstTimeLogin
        CarpenterHasManyProjects(filter: { _deleted: { ne: true } }) {
          items {
            id
            name
            _deleted
          }
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCarpenters = /* GraphQL */ `
  query SyncCarpenters(
    $filter: ModelCarpentersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCarpenters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        phone
        firstName
        lastName
        businessName
        address
        email
        project
        services
        rebuild
        beAMentor
        beMentored
        isApproved
        parish
        userStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const sortByCreatedDate = `
query sortByCreatedDate {
  sortByCreatedDate(sortDirection: ASC, userStatus: APPROVED) {
    items {
      createdAt
    }
  }
}
`;
export const sortByUpdatedDate = `
query sortByUpdatedDate {
  sortByUpdatedDate(userStatus: APPROVED, sortDirection: DESC) {
    items {
      updatedAt
    }
  }
}
`;
