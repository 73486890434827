import Button from "./Button";
import { useNavigate } from "react-router-dom";

function BannerCallToAction({ firstTimeLogin }) {
  const navigate = useNavigate();
  return (
    <div className="mx-auto  flex h-auto items-center justify-center md:max-w-[724px] lg:max-w-[1140px] ">
      <div className="flex flex-1   flex-col items-center justify-center gap-7  rounded-none bg-primary-100 py-[53px] md:gap-[54px] md:rounded-t-2xl  md:py-[80px] lg:gap-14  lg:py-[64px] ">
        <div className="flex flex-col gap-3">
          <h3 className="text-center text-[16px] font-semibold  text-light-primary md:text-[32px] md:font-bold  lg:text-[44px] lg:leading-[52px]">
            {firstTimeLogin
              ? "Thank you for joining"
              : "Join the registry of carpenters"}
          </h3>
          <p className="  mx-auto w-2/3 text-center text-[14px]  font-normal text-light-primary xs:w-full   md:text-[16px] lg:text-xl lg:leading-7">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>

        {!firstTimeLogin && (
          <Button
            label="Join now"
            extraClasses={" !rounded-md  lg:mb-[44px]"}
            onClick={() => {
              navigate("/join");
              window.scrollTo(0, 0);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default BannerCallToAction;
