// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const UserStatus = {
  "APPROVED": "APPROVED",
  "PENDING": "PENDING",
  "SUSPENDED": "SUSPENDED"
};

const Parishes = {
  "SAINT_JOHN": "SAINT_JOHN",
  "SAINT_ANDREW": "SAINT_ANDREW",
  "SAINT_PETER": "SAINT_PETER",
  "SAINT_JOSEPH": "SAINT_JOSEPH",
  "SAINT_GEORGE": "SAINT_GEORGE",
  "SAINT_PAUL": "SAINT_PAUL",
  "SAINT_PATRICK": "SAINT_PATRICK",
  "SAINT_LUKE": "SAINT_LUKE",
  "SAINT_MARK": "SAINT_MARK",
  "SAINT_DAVID": "SAINT_DAVID"
};

const { Services, Projects, Carpenters } = initSchema(schema);

export {
  Services,
  Projects,
  Carpenters,
  UserStatus,
  Parishes
};