import Button from "./../components/Button";
import { Link, useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="mx-auto max-w-[1440px]">
      <div className="relative">
        <svg
          className="mx-auto w-auto"
          viewBox="0 0 1141 363"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M394.523 179.95V154.42L452.983 70.8H488.318V152.57H503.488V179.95H488.318V204H456.683V179.95H394.523ZM458.903 105.58L427.823 152.57H458.903V105.58ZM518.983 134.625C518.983 113.412 523.053 96.7 531.193 84.49C539.457 72.28 552.715 66.175 570.968 66.175C589.222 66.175 602.418 72.28 610.558 84.49C618.822 96.7 622.953 113.412 622.953 134.625C622.953 156.085 618.822 172.92 610.558 185.13C602.418 197.34 589.222 203.445 570.968 203.445C552.715 203.445 539.457 197.34 531.193 185.13C523.053 172.92 518.983 156.085 518.983 134.625ZM591.873 134.625C591.873 122.168 590.517 112.61 587.803 105.95C585.09 99.1667 579.478 95.775 570.968 95.775C562.458 95.775 556.847 99.1667 554.133 105.95C551.42 112.61 550.063 122.168 550.063 134.625C550.063 143.012 550.557 149.98 551.543 155.53C552.53 160.957 554.503 165.397 557.463 168.85C560.547 172.18 565.048 173.845 570.968 173.845C576.888 173.845 581.328 172.18 584.288 168.85C587.372 165.397 589.407 160.957 590.393 155.53C591.38 149.98 591.873 143.012 591.873 134.625ZM640.407 179.95V154.42L698.867 70.8H734.202V152.57H749.372V179.95H734.202V204H702.567V179.95H640.407ZM704.787 105.58L673.707 152.57H704.787V105.58Z"
            fill="#104224"
          />
          <line
            x1="0.425"
            y1="202.575"
            x2="1140.57"
            y2="202.575"
            stroke="#15803D"
            strokeWidth="0.85"
            strokeLinecap="round"
          />
          <ellipse
            cx="1004"
            cy="80"
            rx="63"
            ry="63"
            transform="rotate(90 1004 80)"
            fill="#FFC225"
          />
          <ellipse
            cx="1004"
            cy="80"
            rx="45"
            ry="45"
            transform="rotate(90 1004 80)"
            fill="#FFD66B"
          />
          <ellipse
            cx="1004"
            cy="80"
            rx="28"
            ry="28"
            transform="rotate(90 1004 80)"
            fill="#FFE9B1"
          />
          <path
            d="M927.373 45.8222C929.345 43.7445 932.655 43.7445 934.627 45.8222L994.87 109.308C997.893 112.494 995.634 117.75 991.243 117.75H870.757C866.366 117.75 864.107 112.494 867.13 109.308L927.373 45.8222Z"
            fill="#FFDF8E"
          />
          <rect
            x="865.445"
            y="110.021"
            width="130.611"
            height="91.9781"
            rx="3"
            fill="#FFDF8E"
          />
          <path
            d="M965 202L897 202L897 130L916.5 130C943.286 130 965 151.714 965 178.5L965 202Z"
            fill="#FFC225"
          />
          <path
            d="M927.373 359.177C929.345 361.255 932.655 361.255 934.627 359.177L994.87 295.691C997.893 292.506 995.634 287.25 991.243 287.25H870.757C866.366 287.25 864.107 292.506 867.13 295.691L927.373 359.177Z"
            fill="#FFF8E7"
          />
          <rect
            width="130.611"
            height="91.9781"
            rx="3"
            transform="matrix(1 0 0 -1 865.445 294.978)"
            fill="#FFF8E7"
          />
          <path
            d="M965 203L897 203L897 275L916.5 275C943.286 275 965 253.286 965 226.5L965 203Z"
            fill="#FFEEC1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.0001 105H103.945V137.509H46.0001V105ZM139.779 137.509H46V170.018V170.018V202.526H184V170.018H139.779V137.509Z"
            fill="#FFC225"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.0001 300.526H103.945V268.018H46.0001V300.526ZM139.779 268.017H46V235.509V235.508V203H184V235.509H139.779V268.017Z"
            fill="#FFF8E7"
          />
          <path
            d="M547.52 64.2658V64.2658C540.053 38.8293 554.621 12.1562 580.057 4.68974L585.814 2.99984V2.99984C593.281 28.4363 578.713 55.1094 553.277 62.5759L547.52 64.2658Z"
            fill="#FFD66B"
          />
        </svg>
        <div className="absolute top-[65%] flex w-full flex-col justify-center">
          <p
            className="flex justify-center text-center  text-[40px] font-medium leading-[52px]  text-dark-secondary "
            style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
          >
            oops!! <br /> Page not found
          </p>

          <p className="mt-[30px] flex justify-center text-center text-[20px] leading-[28px] text-dark-primary ">
            The page doesn’t exit or was removed! <br /> We suggest you back to
            home
          </p>
          <Button
            label="back to homepage"
            buttonSize={"small"}
            extraClasses="p-4 mt-[40px] font-medium !text-[20px]"
            onClick={() => {
              navigate("/");
            }}
          />
          <Link to={"/join"} className="mt-[48px] text-center text-[20px]">
            Don't have an account?{" "}
            <span className="text-[#1D28FA]">Join us</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
