function IconDownload() {
	return (
		<svg
			width="22"
			height="21"
			viewBox="0 0 22 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7 6.5L5 6.5C2.79086 6.5 1 8.29086 1 10.5L1 15.5C1 17.7091 2.79086 19.5 5 19.5L17 19.5C19.2091 19.5 21 17.7091 21 15.5L21 10.5C21 8.29086 19.2091 6.5 17 6.5L15 6.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M8 11.5L10.2929 13.7929C10.6834 14.1834 11.3166 14.1834 11.7071 13.7929L14 11.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M11 13.5L11 1.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
}

export default IconDownload;