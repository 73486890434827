function IconEdit() {
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.5378 1.2201C17.5731 0.259454 16.0097 0.260042 15.0457 1.22142L7.5903 8.65835C6.99401 9.25305 6.64344 10.0492 6.60796 10.8891L6.50182 13.4018C6.4535 14.546 7.37116 15.4998 8.52025 15.4999L10.9924 15.5C11.9 15.5 12.7692 15.1349 13.4029 14.4874L20.7984 6.90804C21.7425 5.94344 21.7326 4.40152 20.7761 3.44907L18.5378 1.2201ZM15.9985 2.17018C16.4366 1.73319 17.1473 1.73293 17.5858 2.16958L19.8241 4.39856C20.2588 4.83149 20.2634 5.53236 19.8342 5.97082L18.3489 7.48837L14.5046 3.66008L15.9985 2.17018ZM13.5526 4.60956L8.54304 9.60711C8.18526 9.96393 7.97492 10.4416 7.95363 10.9456L7.8475 13.4583C7.83139 13.8397 8.13728 14.1576 8.52031 14.1576L10.9924 14.1577C11.537 14.1578 12.0585 13.9387 12.4388 13.5502L17.4084 8.44931L13.5526 4.60956Z"
				fill="currentColor"
			/>
			<path
				d="M19.5 11.5V15.5C19.5 18.2614 17.2614 20.5 14.5 20.5H6.5C3.73858 20.5 1.5 18.2614 1.5 15.5V7.5C1.5 4.73858 3.73858 2.5 6.5 2.5H10.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
}

export default IconEdit;