import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SlideNavBar from "../components/SlideNavBar";
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import BannerCallToAction from "../components/BannerCallToAction";
import { useParams, useMatch } from "react-router-dom";
function RootLayer(props) {
  const [isNavSlide, setIsNavSlide] = useState(false);
  const isLogin = useSelector((state) => state.auth.isLogin);
  const { pathname } = useLocation();
  const { cid } = useParams();
  const inEditProject = useMatch("/registry/:cid/:id");
  const userId = useSelector((state) => state.auth.userId);
  const currentCarpenter = useSelector((state) => {
    return state.registry.currentCarpenter;
  });
  const firstTimeLogin =
    isLogin &&
    cid &&
    userId === cid &&
    currentCarpenter &&
    currentCarpenter.firstTimeLogin;

  const footerConditionDesktop = useMemo(
    () => pathname !== "/auth/login" && pathname !== "/not-found",
    [pathname]
  );

  const footerConditionTablet = useMemo(
    () =>
      !pathname.includes("/auth") &&
      !pathname.includes("join") &&
      !pathname.includes("/edit-profile") &&
      !!!inEditProject &&
      pathname !== "/not-found",
    [pathname]
  );

  useEffect(() => {
    setIsNavSlide(false);
  }, [pathname]);

  return (
    <>
      <SlideNavBar setIsNavSlide={setIsNavSlide} isNavSlide={isNavSlide} />
      <div
        className={pathname === "/" ? "bg-test-bg bg-cover bg-no-repeat" : ""}
      >
        <div className="mx-auto max-w-[1440px]">
          <Header setIsNavSlide={setIsNavSlide} />
          <main>
            <ToastContainer />

            <Outlet />

            {((!isLogin &&
              !pathname.includes("auth") &&
              !pathname.includes("not-found") &&
              !pathname.includes("join")) ||
              firstTimeLogin) && (
              <BannerCallToAction firstTimeLogin={firstTimeLogin} />
            )}
          </main>
        </div>
        <Footer
          className={`${footerConditionDesktop ? "lg:block" : "lg:hidden"} ${
            footerConditionTablet ? "block" : "hidden"
          }`}
        />
      </div>
    </>
  );
}

export default RootLayer;
