import { useLocation } from "react-router";
import { useEffect } from "react";
const useScrollTop = () => {
  const { pathname } = useLocation();
  //Scroll top on refresh
  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };
  //Scroll top on navigating
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};
export default useScrollTop;
