import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getACarpenterById } from "../utils/aws.services";
import { Auth } from "@aws-amplify/auth/lib-esm/Auth";
const initialState = {
  token: null,
  isLogin: false,
  userData: null,
  user: null,
  userId: null,
  timeout: null,
  isAdmin: false,
  firstTimeLogin: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.isLogin = true;
      state.user = action.payload;
      state.userId = action.payload.username;
      state.isAdmin = action.payload.isAdmin;
      state.firstTimeLogin = !!action.payload.firstTimeLogin;
    },
    signOut(state, action) {
      state.isLogin = false;
      state.isAdmin = false;
    },
    setUserData(state, action) {
      state.userData = action.payload;
    },
    setFirstTimeLogin(state, action) {
      state.firstTimeLogin = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;

export const loginToServer = (email, password) => {
  return async (dispatch, getState) => {
    let user;
    try {
      //Replace later with http request or AWS authenticator
      user = await Auth.signIn({ username: email, password: password });
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (user) {
      try {
        await dispatch(fetchInformation(user));
      } catch (error) {
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
};

export const fetchInformation = (user) => {
  return async (dispatch, getState) => {
    const currenSession = await Auth.currentSession();
    const usergroup = currenSession.getIdToken().payload["cognito:groups"];
    let isAdmin = false;
    if (usergroup && usergroup.length > 0) {
      isAdmin = usergroup[0] === "admin";
    }

    dispatch(authActions.login({ ...user, isAdmin }));
    let carpenterData;
    try {
      carpenterData = await getACarpenterById(user.username);
    } catch (error) {
      throw error;
    }
    dispatch(authActions.setUserData(carpenterData));
  };
};

export const signOut = () => {
  return async (dispatch, getState) => {
    try {
      await Auth.signOut();
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    dispatch(authActions.signOut());
  };
};
